.Container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  .Track {
    position: relative;
    margin-bottom: 16px;
    width: 100%;
    height: 4px;
    border-radius: 100px;
    background-color: var(--lines);
    overflow: hidden;

    @media screen and (max-width: 932px) {
      margin-bottom: 12px;
    }

    .Progress {
      position: absolute;
      height: 100%;
      background-color: var(--refined-teal);
      width: 0;
      transition: all 0.25s ease;
    }
  }

  .Counter {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: normal;
    letter-spacing: -1px;
    color: var(--refined-teal);
    background: var(--light-teal);
    border-radius: 4px;
    padding: 6px 16px;

    @media screen and (max-width: 932px) {
      padding: 4px 8px;
      font-size: 14px;
    }
  }
}
