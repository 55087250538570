.select-tags-wrapper {
  width: 100%;

  .select-tags-header {
    color: var(--dark-grey);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    height: 22px;
  }

  .custom-tags-select {
    width: 100%;
    min-height: 44px;

    display: flex;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--cool-grey) !important;
    box-shadow: none !important;

    .rs-picker-toggle-placeholder {
      color: var(--dark-grey);
    }

    .rs-tag {
      height: 25px;
      background: var(--light-teal);
      border-radius: 1000px;

      font-weight: 400;
      font-size: 12px;
      color: var(--refined-teal);

      .rs-tag-text {
        margin-right: 5px;
      }
    }
  }
}

.custom-tags-select-menu {
  margin-top: 4px;
  border: 1px solid var(--cool-grey);
  box-shadow: none !important;
  border-radius: 8px !important;

  .rs-picker-check-menu-items {
    margin-bottom: 0px;

    .rs-check-item-focus {
      @media screen and (max-width: 932px) {
        background: none;
        background-color: transparent;
      }
    }

    .rs-check-item:not(.rs-checkbox-disabled):hover {
      @media screen and (max-width: 932px) {
        background-color: transparent;
      }
    }

    .rs-checkbox-checker {
      label {
        display: flex;
        align-items: center;
        height: 44px;
        font-size: 14px;
        font-weight: 400;
        color: var(--dark-grey);
      }

      &:hover {
        background: var(--light-teal);

        @media screen and (max-width: 932px) {
          background: none;
        }
      }
    }

    .rs-checkbox-wrapper {
      top: 14px;

      .rs-checkbox-inner {
        &:before {
          border-radius: 5px;
          border-color: var(--dark-grey);
        }

        &:after {
          border-width: 0 1px 1px 0;
          width: 4px;
          height: 6px;
          margin-top: 4px;
          margin-left: 6px;
          opacity: 1;
          border-color: var(--light-teal);
        }
      }
    }

    .rs-checkbox-checked {
      background-color: var(--light-teal);

      .rs-checkbox-checker {
        @media screen and (max-width: 932px) {
          background-color: var(--light-teal);
        }
      }

      label {
        font-weight: 400 !important;
        color: var(--matte-black);
      }

      .rs-checkbox-inner {
        &:before {
          border-radius: 5px;
          border-color: var(--refined-teal);
          background-color: var(--refined-teal);
        }

        &:after {
          border-width: 0 1px 1px 0;
          width: 4px;
          height: 6px;
          margin-top: 4px;
          margin-left: 6px;
          opacity: 1;
          border-color: var(--light-teal);
        }
      }
    }
  }
}
