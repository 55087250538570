.ButtonStyled {
  cursor: pointer;
  transition: 0.3s;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  border-radius: 100px;
  background-color: transparent;

  font-weight: 600;
  line-height: 18px;
  text-transform: uppercase;
  white-space: nowrap;

  .ChildrenContainer {
    padding: 4px 0;
  }

  svg:has(+ span) {
    margin-right: 8px;
    flex-shrink: 0;
  }

  &:disabled {
    opacity: 0.5;
  }

  &:hover {
    filter: drop-shadow(0px 11px 34px rgba(101, 184, 117, 0.4));
  }

  &.Outlined {
    color: var(--refined-teal);
    border: solid 2px transparent;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
      linear-gradient(277deg, var(--refined-teal) -0.11%, var(--chartreuse) 98.03%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: var(--button-shadow);
  }

  &.Attention {
    background: var(--red);
    color: var(--white);

    &:hover {
      filter: drop-shadow(0px 11px 34px rgba(141, 49, 76, 0.4));
    }
  }

  &.Contained {
    color: var(--white);
    background: linear-gradient(277deg, var(--refined-teal) -0.11%, var(--chartreuse) 98.03%);
  }

  &.Transparent {
    color: var(--refined-teal);
    border: none;
    background-color: transparent;
  }

  &.TransparentRed {
    color: var(--red);
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;

    svg {
      margin-right: 8px;
      width: 12px;
      height: 12px;
      flex-shrink: 0;
    }
  }

  &.TransparentBold {
    padding: 8px;
    display: flex;
    align-items: center;

    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: var(--refined-teal);

    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: none;

    svg:has(+ span) {
      margin-right: 6px;
      flex-shrink: 0;
    }

    .ChildrenContainer {
      padding: 0;
    }
  }

  &.Tag {
    height: 25px;
    font-size: 12px;
    line-height: 14px;
    padding: 5.5px 16px;
  }

  &.Long {
    min-width: 175px;
    height: 32px;
    font-size: 12px;
    line-height: 14px;
    padding: 9px 16px;
  }

  &.Small {
    height: 32px;
    font-size: 12px;
    line-height: 14px;
    padding: 9px 16px;
  }

  &.Link {
    line-height: normal;
    font-size: 12px;
    padding: 4px 8px;
  }

  &.Icon {
    padding: 10px;
  }

  &.Normal {
    height: 50px;
    font-size: 16px;
    padding: 9px 16px;
  }

  &.FullWidth {
    width: 100%;
  }

  &.JustifyStart {
    justify-content: start;
  }

  &.WrapText {
    white-space: normal;
    min-height: 32px;
    height: auto;
    padding: 0 20px;
  }

  &.Bold {
    font-size: 12px;
    font-weight: 700;
    line-height: 15.6px;
  }
}
