@import '../../../../../styles/mixins.scss';

.AssignedToContainer {
  display: flex;
  align-items: center;

  .AssignedTo {
    padding: 4px 8px;
    border-radius: 4px;
    font-weight: 400;
    font-size: 12px;
    color: var(--refined-teal);
    background-color: var(--light-teal);
    max-width: 90px;
    border-radius: 999px;

    @media screen and (max-width: 932px) {
      max-width: none;
    }

    @include ellipsis;
  }

  .AssignedToMore {
    font-weight: 400;
    margin-left: 4px;
    white-space: nowrap;
    font-size: 12px;
  }

  .TooltipText {
    font-weight: 400;
    font-size: 14px;
  }
}
