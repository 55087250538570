.Compare {
  display: flex;
  justify-content: center;
  margin-bottom: 21px;

  .CompareIcon {
    width: 15px;
    height: 20px;
    color: var(--refined-teal);
    transform: rotate(90deg);
  }
}
