.StatusRow {
  margin-bottom: 16px;

  span {
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    color: var(--dark-grey);
    margin-bottom: 5px;
  }

  .StatusField {
    padding: 12px;
    border-radius: 8px;
    border: 1px solid var(--cool-grey);
  }
}

.StatusInline {
  display: flex;
  align-items: center;

  .Status {
    margin-right: 8px;
  }
}

.Status {
  width: 6px;
  height: 6px;
  margin-right: 11px;
  border-radius: 50%;
}

.Disabled,
.Active {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
}

.Disabled {
  color: var(--red);

  .Status {
    background: var(--red);
  }
}

.Active {
  color: var(--accept);

  .Status {
    background: var(--accept);
  }
}

.Sent {
  color: var(--status-sent);

  .Status {
    background: var(--status-sent);
  }
}

.Opened {
  color: var(--status-opened);

  .Status {
    background: var(--status-opened);
  }
}

.Completed {
  color: var(--status-completed);

  .Status {
    background: var(--status-completed);
  }
}

.SendingError {
  color: var(--red);

  .Status {
    background: var(--red);
  }
}
