.Languages {
  height: 34px;
  padding-right: 12px;
  margin-right: 12px;
  border-right: 1px solid var(--cool-grey);

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  .Icon {
    margin-right: 10px;
  }

  .CurrentLanguage {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    color: var(--dark-grey);
    text-transform: uppercase;

    .Active {
      color: var(--refined-teal);
    }
  }
}
