.Assessment_accordion {
  border-radius: 8px;
  margin-bottom: 3px;
  padding: 12px 16px 0;

  &.hidden {
    padding-bottom: 12px;
  }

  &.level {
    &_0 {
      background-color: var(--white);
      box-shadow: var(--block-shadow);

      .Accordion_title {
        font-size: 24px;
        line-height: 28px;
      }
    }

    &_1 {
      background-color: var(--aqua-spring);

      .Accordion_title {
        font-size: 20px;
        line-height: 23px;
      }
    }

    &_2 {
      background-color: var(--iceberg);

      .Accordion_title {
        font-size: 16px;
        line-height: 18px;
      }
    }

    &_3 {
      background-color: var(--jagged-ice);
    }

    &_4 {
      background-color: var(--powder-blue-1);
    }

    &_5 {
      background-color: var(--powder-blue-2);
    }

    &_6 {
      background-color: var(--morning-glory-1);
    }

    &_7 {
      background-color: var(--morning-glory-2);
    }

    &_8 {
      background-color: var(--bermuda);
    }
  }

  .Accordion_title_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .Accordion_title {
      font-weight: 700;
      letter-spacing: -1.5px;

      display: flex;
      align-items: center;

      .Arrow {
        margin-right: 16px;
        transform: rotate(180deg);

        &.rotated {
          transform: rotate(0deg);
        }
      }
    }
  }

  .Accordion_body {
    padding-bottom: 12px;

    &.hidden {
      display: none;
      height: 0px;
    }
  }
}
