@import '../../styles/mixins.scss';

.custom-table-wrapper {
  width: 100%;
  // margin-bottom: 12px;
}

.pagination-page-size {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .per-size-block {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__text {
      margin-right: 8px;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: var(--dark-grey);
    }

    div:last-child {
      margin-bottom: 0 !important;
    }
  }
}

.custom-table {
  &.new-design {
    .MuiDataGrid-row .MuiDataGrid-cell:nth-child(1) {
      color: var(--matterhorn);
    }

    .MuiDataGrid-columnHeaders {
      border-bottom: 1px solid var(--oyster-bay);
      color: var(--matterhorn);
      font-size: 12px;
      font-weight: 400;
      line-height: 15.6px;
    }

    button:has(> .MuiDataGrid-row):not(:last-child) {
      border-bottom: 1px solid var(--oyster-bay);
    }

    .MuiDataGrid-row {
      width: 100% !important;
      border-radius: 0px;
      padding-left: 0px;
      background-color: transparent;
      box-shadow: unset;
      margin-top: 0 !important;
    }
  }

  &.MuiDataGrid-root {
    border: none !important;

    .MuiDataGrid-sortIcon {
      opacity: inherit !important;
    }

    .MuiDataGrid-columnHeaderTitle {
      white-space: normal;
      line-height: 15px;
    }

    .MuiDataGrid-iconButtonContainer {
      visibility: visible !important;
      width: auto !important;
    }

    .MuiDataGrid-virtualScroller {
      overflow: visible !important;
    }

    .MuiDataGrid-columnHeader:focus,
    .MuiDataGrid-cell:focus {
      outline: none;
    }

    .MuiDataGrid-row {
      cursor: pointer;
      transition: 0.2s;

      &:hover {
        background-color: var(--white);
        border: 1px solid var(--refined-teal);
      }
    }
  }

  .MuiDataGrid-main {
    overflow: visible;
  }

  .MuiDataGrid-row.Mui-selected {
    background-color: var(--white) !important;
    border: 1px solid var(--refined-teal);
    padding-left: 0px;
    border-left: 8px solid var(--refined-teal);

    &:hover {
      border-left: 8px solid var(--refined-teal);
    }
  }

  .MuiDataGrid-columnHeaders {
    border: none;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: var(--dark-grey);
  }

  .MuiDataGrid-iconSeparator {
    display: none;
  }

  .MuiDataGrid-row {
    width: 99% !important;
    border: 1px solid transparent;
    border-radius: 8px;
    padding-left: 8px;
    background-color: var(--white);
    box-shadow: var(--block-shadow);

    .MuiDataGrid-cell {
      border: none;
      font-size: 12px;
      line-height: 16px;
    }
    .MuiDataGrid-cell:nth-child(1) {
      color: var(--dark-grey);
    }

    &.MuiDataGrid-row--invalid {
      width: 99% !important;
      background-color: var(--white) !important;
      border: 1px solid var(--red);
      padding-left: 0px;
      border-left: 8px solid var(--red);
      margin-bottom: 0;

      &:hover {
        border: 1px solid var(--red);
        border-left: 8px solid var(--red);
      }
    }
  }

  .MuiDataGrid-footerContainer {
    border: none;
    display: flex;
    justify-content: center;
  }
}

.MuiPagination-ul {
  .MuiPaginationItem-root {
    margin: 0;
    border-radius: 0;
    background-color: var(--white) !important;

    &.Mui-selected {
      color: var(--refined-teal);
      border: 1px solid var(--refined-teal);
      box-shadow: var(--table-shadow);
    }

    &:focus-visible {
      outline: -webkit-focus-ring-color auto 1px !important;
    }
  }
}

.MuiDataGrid-row--invalid-wrapper {
  position: relative;

  p {
    text-align: right;
    color: var(--red);
    position: absolute;
    top: 4px;
    right: 12px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    @include ellipsis;
  }
}
