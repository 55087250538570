@import '../../styles/mixins.scss';

.Breadcrumb {
  color: var(--cool-grey);
  padding: 8px 0;

  @media screen and (max-width: 1180px) {
    padding: 12px 16px;
    width: 100%;
    border-bottom: 1px solid var(--cool-grey);
  }

  &_Item {
    color: var(--dark-grey);
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;

    text-transform: none;
    text-decoration: none;
    @include breakText();

    &:hover {
      color: var(--refined-teal);
      text-transform: none;
      text-decoration: none;
    }

    &:focus {
      color: var(--refined-teal);
      text-transform: none;
      text-decoration: none;
    }

    &.Active {
      color: var(--refined-teal);
    }
  }
}
