.Container {
  width: 495px;

  @media screen and (max-width: 932px) {
    width: 100%;
    padding: 0 16px;
  }
}

.SignIn {
  .SignInForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .ForgotLink {
    margin-left: auto;
    margin-bottom: 32px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-decoration: none;
    color: var(--refined-teal);
  }
}
