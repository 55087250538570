.Container {
  @media screen and (max-width: 932px) {
    margin-top: 16px;
  }

  .LastAssessmentDateText {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }
}
