@import '../../../../styles/mixins.scss';

.ListLevel {
  height: 0px;
  overflow: hidden;

  &.Active {
    padding: 8px 0px;
    overflow: visible;
    height: 100%;
  }

  > li {
    &:before {
      content: '';
      position: absolute;
      top: 8.5px;
      left: -30px;
      border-left: 1.5px solid var(--refined-teal);
      border-bottom: 1.5px solid var(--refined-teal);
      width: 77px;
      height: 15px;
      z-index: -1;
    }

    &:first-child:before {
      top: -45px;
      border-top: 1.5px solid var(--refined-teal);
      border-bottom: none;
      border-top-left-radius: 4px;
      height: 68px;
    }

    &:last-child:before {
      border-bottom-left-radius: 4px;
    }

    &:nth-last-child(2):after {
      border-bottom-left-radius: 4px;
    }

    &:after {
      position: absolute;
      content: '';
      top: 22px;
      left: -30px;
      border-left: 1.5px solid var(--refined-teal);
      border-top: 1.5px solid var(--refined-teal);
      width: 77px;
      height: 100%;
      z-index: -1;
    }

    &:last-child:after {
      display: none;
    }

    &:only-child:before {
      display: block;
      height: 69px;
      border-bottom: 1.5px solid var(--refined-teal);
    }
  }

  &.Lg {
    > li {
      &:before {
        top: 8.5px;
        left: -30px;
        z-index: -1;
      }

      &:first-child:before {
        top: -45px;
        border-top-left-radius: 4px;
      }

      &:after {
        top: 22px;
        left: -30px;
        z-index: -1;
      }

      &:only-child:before {
        height: 69px;
      }
    }
  }

  &.Md {
    > li {
      &:before {
        top: 5.5px;
        left: -35px;
        z-index: -2;
      }

      &:first-child:before {
        top: -38px;
        height: 68px;
      }

      &:after {
        top: 19px;
        left: -35px;
        z-index: -2;
      }

      &:last-child:after {
        display: none;
      }

      &:only-child:before {
        height: 59px;
        top: -37.5px;
      }
    }
  }

  &.Sm {
    > li {
      &:before {
        top: 5.5px;
        left: -35px;
        z-index: -3;
      }

      &:first-child:before {
        top: -30px;
        height: 68px;
      }

      &:after {
        top: 19px;
        left: -35px;
        z-index: -3;
      }

      &:only-child:before {
        top: -30px;
        height: 47px;
      }
    }
  }
}
