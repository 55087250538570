.WeightBlock {
  display: flex;
  width: 100%;
  margin-bottom: 10px;

  input {
    margin-left: 32px;
  }

  div {
    display: flex;
    align-items: center;

    label {
      margin: 0 8px;
    }

    p {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: var(--dark-grey);
    }

    .toggled {
      color: var(--refined-teal);
    }
  }
}
