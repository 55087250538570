.Container {
  margin-top: 23px;
}

.StepsWrapper {
  background: var(--white);
  box-shadow: var(--block-shadow);
  padding: 16px 24px;
  border-radius: 8px;
}
