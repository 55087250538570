.SectionWrapper {
  width: 100%;
  background: var(--white);
  box-shadow: var(--block-shadow);
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 32px;

  @media screen and (max-width: 932px) {
    margin-bottom: 0px;
  }

  .AccelerantContainer:not(:last-child) {
    padding-bottom: 20px;
    margin-bottom: 20px;
  }

  > div:not(:last-child) {
    border-bottom: 1px solid var(--cool-grey);
  }

  .AdditionalAccelerants {
    display: flex;
    width: 100%;
    margin-top: 25px;

    @media screen and (max-width: 932px) {
      flex-direction: column;
      gap: 24px;
    }

    > div {
      flex: 1;
    }

    > div:not(:last-child) {
      margin-right: 20px;

      @media screen and (max-width: 932px) {
        margin-right: 0;
      }
    }
  }
}

.ComparisonContainer {
  > div:not(:last-child) {
    margin-bottom: 8px;
  }
}
