@import 'styles/mixins';

.ModalWrapper {
  width: 722px !important;
}

.Wrapper {
  display: flex;
  gap: 24px;
  margin-top: 24px;
}
