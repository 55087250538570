.Container {
  width: 64px;
  height: 4px;
  border-radius: 999px;
  background-color: var(--cool-grey);
}

.Line {
  width: 50%;
  height: 100%;
  border-radius: 999px;

  &.Blue {
    background-color: var(--refined-teal);
  }

  &.Yellow {
    background-color: var(--chartreuse);
  }
}
