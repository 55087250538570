.CheckBoxButton {
  padding: 0 !important;
  color: var(--refined-teal);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;

  button {
    > span:first-child {
      margin: -9px;
      margin-right: 0;
      padding-right: 8px;
    }

    svg {
      margin-right: 0 !important;
      width: 20px !important;
      height: 20px !important;
    }
  }
}
