.colorOptions {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;
  align-items: center;
  gap: 5px;
}

.clicked {
  border: 1px solid var(--alto);
  border-bottom: none;
}

.popupWrapper {
  display: inline;
  position: relative;
}

.popup {
  position: absolute;
  right: 0px;
  background-color: var(--white);
  padding: 6px 10px;
  border: 1px solid var(--alto);
  height: fit-content;
  z-index: 1;

  p {
    text-align: center;
    opacity: 0.7;
    width: 100%;
  }
}

.option,
.hexPreview {
  width: 16px;
  height: 16px;
  background-color: var(--black);
}

.colorPicker {
  & form {
    display: flex;
    align-items: center;
    column-gap: 5px;
    width: 100%;
  }

  & input {
    width: 65%;
    height: 1.3em;
    border: 1px solid var(--alto);
    border-radius: 5px;
    padding-left: 5px;
  }

  & button {
    margin: 0;
    padding: 0;
    cursor: pointer;
  }

  button {
    opacity: 1;
    background: none;
  }
}
