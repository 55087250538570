@import '../../styles/mixins.scss';

.Container {
  min-width: 0;
  max-width: 100%;
  display: flex;
  align-items: center;

  span {
    display: inline-block;
    max-width: 100%;
    padding: 4px 12px;
    font-size: 12px;
    border-radius: 1000px;
    background-color: var(--light-teal);
    color: var(--refined-teal);
    @include ellipsis;
  }

  img {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-right: 8px;
  }
}
