@import '../../../../../../../../styles/mixins.scss';

.SelectPickerContainer {
  @media screen and (max-width: 1180px) {
    width: auto !important;
    min-width: 0 !important;
    flex: 1;
  }

  :global(.rs-picker-toggle.rs-btn.rs-btn-default) {
    @media screen and (max-width: 1180px) {
      width: 100%;
    }
  }
}

.MenuContainer {
  margin-top: 5px;
  filter: drop-shadow(0px 8px 25px rgba(79, 122, 165, 0.14));
  padding-top: 0 !important;

  :global {
    .rs-picker-select-menu {
      margin-bottom: 0;

      .rs-picker-select-menu-item {
        padding: 0;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 140%;
        color: var(--matte-black);
      }

      .rs-picker-select-menu-item-active {
        background: var(--light-teal);
      }
    }
  }
}

.OptionContainer {
  &:hover {
    background-color: var(--bermuda);
    color: var(--matte-black);
  }

  &.Group {
    background-color: var(--light-teal);
  }

  .Label {
    display: block;
    padding: 8px 12px;
    @include ellipsis;
  }
}
