@import '../../../../../../styles/mixins.scss';

.Element {
  display: flex;
  align-items: center;
  font-weight: 400;
  line-height: 16px;
  font-size: 14px;
  height: 48px;
  z-index: 20;
  width: 550px;
  padding-left: 13px;
  margin-bottom: 4px;
  background: var(--white);

  border-radius: 8px;
  border: 1px solid var(--cool-grey);
  border-left: 8px solid var(--cool-grey);

  &.Md,
  &.Sm {
    font-size: 10px;
    height: 34px;
  }

  &.Checked {
    background: var(--light-teal);
    border-color: var(--refined-teal);
  }

  .Avatar {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    margin-right: 10px;
  }

  .Path {
    margin-right: 23px;
  }

  .Name {
    width: 300px;
    margin-right: 20px;

    @include ellipsis;

    span {
      cursor: pointer;
    }
  }

  .Description {
    width: 450px;
  }
}
