.CheckboxWrapper {
  margin-bottom: 8px;
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;

  @media screen and (max-width: 932px) {
    margin-bottom: 0;
  }

  .ChoiceColumn {
    width: 49%;

    @media screen and (max-width: 932px) {
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }

  .CheckboxAssessment {
    width: 100%;
    min-height: 44px;
    background: var(--white);
    border: 1px solid var(--cool-grey);
    border-radius: 6px;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }

    :global(.rs-radio-checker .rs-radio-wrapper),
    :global(.take-checkbox .rs-checkbox-wrapper) {
      top: 14px;

      :global(.rs-radio-inner:before) {
        border: 1.5px solid var(--dark-grey) !important;
      }
    }

    &:global(.rs-checkbox-checked),
    &:global(.rs-radio-checked) {
      background: var(--light-teal);
      border: 1px solid var(--refined-teal);

      :global(.rs-radio-checker .rs-radio-inner:before) {
        border: 1.5px solid var(--refined-teal) !important;
      }

      label {
        color: var(--refined-teal);
      }
    }

    :global(.rs-checkbox-checker) {
      label {
        margin-left: 0;
        padding-left: 40px;
      }
    }

    :global(.rs-radio-checker),
    :global(.rs-checkbox-checker) {
      width: 100%;
      height: 100%;
      min-height: inherit;
      padding-left: 0;
      padding-top: 0;
      padding-bottom: 0;
    }

    :global(.take-checkbox .rs-checkbox-checker label) {
      margin-left: 0 !important;
    }

    label {
      word-wrap: break-word;
      white-space: pre-wrap;
      word-break: break-word;
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
      min-height: inherit;
      color: var(--dark-grey);
      font-size: 14px;
      font-weight: 400;
      padding: 10px;
      padding-left: 36px;
    }
  }
}
