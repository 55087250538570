.Tooltip {
  cursor: pointer;
}

.Container {
  h2 {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    text-transform: uppercase;
    margin-bottom: 7px;
  }

  .Content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
    }
  }
}
