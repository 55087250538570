.Eula {
  margin-bottom: 16px;
  font-size: 14px;
  color: var(--matte-black);

  a,
  a:hover,
  a:visited {
    color: var(--refined-teal);
  }
}
