.CardContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--lines);
  background: var(--white);
  border-radius: 8px;

  .CardHeaderContainer {
    margin-bottom: 24px;
  }

  .ContentContainer {
    padding: 24px 0;
    width: 100%;
  }

  .DesktopContainer {
    width: 100%;
    padding: 24px;

    > div:last-child {
      margin-bottom: 0;
    }
  }
}
