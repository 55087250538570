.NoResults {
  margin-top: 80px;
  display: flex;
  justify-content: center;

  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: var(--dark-grey);
  }
}
