.MobileControls {
  position: absolute;
  background-color: var(--white);
  border-bottom: 1px solid var(--cool-grey);
  visibility: hidden;
  width: 100%;
  opacity: 0;
  transition: all 0.2s ease;
  padding: 0 12px;
  display: none;
  z-index: -1;

  @media screen and (max-width: 1180px) {
    display: block;
  }

  &.Active {
    visibility: visible;
    padding: 12px;
    z-index: 1001;
    opacity: 1;
  }

  .OrganizationSelector {
    margin-bottom: 24px;
  }

  .DashboardSwitchContainer {
    margin-bottom: 12px;
  }
}
