.Error {
  margin-bottom: 6px;

  p {
    font-size: 12px;
    color: var(--red);
  }
}

.DropdownPlaceholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.FileInfo {
  display: flex;
  align-items: center;

  .Name {
    margin-left: 11px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: var(--matte-black);
  }
}

.DragText {
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: var(--matte-black);
  margin-bottom: 8px;
}

.DragImage {
  margin-bottom: 16px;
}

.DragSelectText {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;

  /* Refined teal */
  color: var(--dark-grey);

  .ActiveText {
    margin-right: 4px;
    color: var(--refined-teal);
  }
}
