.CardFooter {
  width: 100%;
  min-height: 64px;
  padding: 16px 24px;
  border-top: 1px solid var(--lines);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin-top: auto;

  @media screen and (max-width: 932px) {
    padding: 16px;
  }

  button {
    min-width: 132px;

    &:last-child {
      margin-left: auto;
    }

    @media screen and (max-width: 932px) {
      min-width: auto;
      flex: 1;
    }
  }
}
