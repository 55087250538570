@import '../../../../../../../../../../styles/mixins.scss';

.ChartInfo {
  .Title {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: var(--matte-black);
    margin-bottom: 4px;

    @media screen and (max-width: 932px) {
      text-align: center;
      font-size: 12px;
      white-space: pre-wrap;
    }
  }

  .ChartInfoLabel {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: var(--dark-grey);
    display: flex;

    @media screen and (max-width: 932px) {
      font-size: 11px;
      line-height: normal;
      flex-direction: column;

      &:has(~ .ChartDescription) {
        margin-bottom: 32px;
      }
    }

    &.ChartInfoLabelColumn {
      > span {
        flex-direction: column;
      }
    }

    > span {
      display: flex;
      padding-right: 8px;

      @media screen and (max-width: 932px) {
        display: inline;
        text-align: center;
        padding-right: 0px;
        font-size: 11px;
      }

      &:first-child {
        @media screen and (max-width: 932px) {
          &::after {
            content: '';
            display: block;
            width: 64px;
            height: 1px;
            background-color: var(--cool-grey);
            margin: 4px auto;
          }
        }
      }

      &:last-child {
        padding-left: 8px;

        @media screen and (max-width: 932px) {
          padding-left: 0px;
        }
      }

      &:not(:last-child) {
        border-right: 1px solid var(--cool-grey);

        @media screen and (max-width: 932px) {
          border-right: none;
        }
      }
    }
  }
}

.ChartDescription {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
  width: 100%;

  @media screen and (max-width: 932px) {
    margin-top: auto;
  }

  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: var(--matte-black);
    @include ellipsis;
  }

  .ChartDescriptionLabel {
    background: var(--venetian-red);
    border-radius: 1000px;
    padding: 4px 8px;
    color: var(--white);
    display: inline-flex;
    justify-content: center;
    align-items: center;

    &.High {
      background: var(--refined-teal);

      svg {
        transform: rotate(180deg);
      }
    }

    svg {
      path {
        fill: var(--white);
      }
    }
  }
}
