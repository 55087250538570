.Hamburger {
  display: none;
  cursor: pointer;

  @media screen and (max-width: 1180px) {
    display: block;
  }

  &.Active {
    span {
      &:nth-child(1) {
        transform: translateY(6px) rotate(45deg);
      }

      &:nth-child(2) {
        width: 0%;
        opacity: 0;
      }

      &:nth-child(3) {
        transform: translateY(-6px) rotate(-45deg);
      }
    }
  }

  span {
    width: 18px;
    height: 2px;
    background-color: var(--matte-black);
    border-radius: 10px;
    display: block;
    margin: 4px auto;
    transition: all 0.3s ease-in-out;
  }
}
