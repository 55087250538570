.CardContainer {
  margin-bottom: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--lines);
  background: var(--white);
  border-radius: 8px;
  align-items: center;
}

.WelcomePage {
  min-height: 300px;
  position: relative;
  margin-bottom: 0px;

  .WelcomeText {
    width: 100%;
    margin: 16px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .Name {
      width: 90%;
      border: none;
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      line-height: 43px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      letter-spacing: -2px;
      color: var(--matte-black);
    }

    .Description {
      width: 90% !important;
      border: none;
      margin-top: 4px;
      display: flex;
      justify-content: center;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      align-items: center;
      text-align: center;
      color: var(--dark-grey);
      resize: none;
    }
  }

  .WelcomeDelete {
    position: absolute;
    top: 14px;
    right: 14px;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;

    svg {
      width: 18.5px;
      height: 20.25px;
    }
  }
}

.AssessmentImage {
  margin-top: 16px;
  position: relative;
  display: flex;
  height: 119px;
  width: 412px;
  border-radius: 0px;
  margin-bottom: 2px;
  justify-content: center;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.ButtonsContainer {
  cursor: pointer;
  position: absolute;
  right: -10px;
  display: flex;
  justify-content: flex-end;

  svg {
    width: 11px;
    height: 12px;
  }
}

.ShortTextEntry {
  width: 100%;

  .Input {
    margin: 16px 0;
  }
}

.InnerBody {
  padding: 16px 24px 0 24px;
  width: 100%;
}

.Title {
  display: flex;
  position: relative;
  justify-content: space-between;
}

.CardNameDescriptionBlock {
  width: 100%;

  .InputBlock {
    display: flex;
  }

  small {
    color: var(--red);
  }

  .Name {
    resize: none;
    width: 90%;
    border: none;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    display: flex;
    align-items: center;
    letter-spacing: -1px;
    color: var(--matte-black);
  }

  .Description {
    resize: none;
    width: 90%;
    border: none;
    padding-left: 5px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    display: flex;
    align-items: center;
    color: var(--dark-grey);
  }
}

.CardBottomLine {
  background: var(--white);
  border-top: 1px solid var(--lines);
  display: flex;
  padding: 0 24px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 64px;
  border-radius: 0 0 8px 8px;

  input {
    padding: 6px 12px 6px 12px;
    gap: 10px;
    width: 123px;
    border: 1px solid var(--cool-grey);
    border-radius: 4px;
    font-weight: 400;
    font-size: 12px;
    color: var(--dark-grey);
  }

  input::placeholder {
    font-weight: 400;
    font-size: 12px;
    color: var(--dark-grey);
  }
}

.QuestionSliderInput {
  padding-top: 16px;
}

.SliderInput {
  width: 100%;
  padding: 12px;
  height: 44px;
  background: var(--white);
  border: 1px solid var(--cool-grey);
  border-radius: 8px;
  text-align: center;
  margin-bottom: 32px;
}

.SliderWrapper {
  margin-bottom: 24px;

  .SliderBar {
    height: 8px;
    left: 5px;
    background: var(--lines) !important;
    border-radius: 1000px;

    :global(.rs-slider-progress-bar) {
      background: var(--refined-teal);
      height: 8px;
    }
  }

  .HandleClass {
    top: -100%;

    &::before {
      border: none;
      box-shadow: none !important;
      width: 24px;
      height: 24px;
      background: var(--refined-teal);
      margin-left: -10px;
    }
  }
}

.DropdownCard {
  .DropdownInput {
    width: 100%;
    resize: none;
    width: 90%;
    border: none;
    display: flex;
    align-items: center;
    letter-spacing: -1px;
    color: var(--matte-black);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: var(--dark-grey);
  }

  .ScoreBlock {
    display: flex;
    align-items: center;

    .WeightInput {
      margin-right: 10px;
    }
  }

  .SelectInput {
    cursor: pointer;
    user-select: none;
    margin: 16px 0 8px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding: 12px;
    width: 100%;
    border: 1px solid var(--cool-grey);
    border-radius: 8px;
  }

  .SelectMenu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 12px;
    width: 100%;
    border: 1px solid var(--cool-grey);
    border-radius: 8px;
    margin-bottom: 16px;

    .SelectMenuItem {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 7px;

      .OptionBlock {
        flex: 1;
        display: flex;
        align-items: center;

        input {
          width: 80px;
        }

        svg {
          cursor: pointer;
          margin-left: 15px;
        }
      }
    }
  }
}

.WeightInput {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 12px;
  gap: 10px;
  width: 74px;
  height: 29px;
  background: var(--white);
  border: 1px solid var(--cool-grey);
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: var(--dark-grey);
}

.WeightInput::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: var(--dark-grey);
}

.SmallGreyText {
  flex: 1;
  min-width: 0;
  border: none;
  resize: none;
  font-weight: 400;
  font-size: 14px;
  color: var(--dark-grey);
}

.AddOption {
  display: flex;
  align-items: center;
  cursor: pointer;

  p {
    margin-left: 10.5px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 140%;
    color: var(--refined-teal);
    text-transform: uppercase;
  }
}

.BorderError {
  border: 1px solid red !important;
  border-radius: 4px;
}

.FromToRow {
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .Input {
    width: 47%;
    outline: none;
    resize: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;

    margin: 0;
    padding: 12px;
    background: var(--white);

    border-radius: 8px;
    border: 1px solid var(--cool-grey);

    &.Invalid {
      border-color: var(--red);
    }

    &.Valid {
      border-color: var(--refined-teal);
    }

    &::placeholder {
      color: var(--dark-grey);
    }

    &:disabled {
      border: none;
      color: var(--dark-grey);
      background-color: var(--black-squeeze);
    }
  }

  .Separator {
    width: 24px;
    height: 1px;
    background: var(--dark-grey);
  }
}

.ChoiceBlock {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 8px;
  margin-top: 16px;

  > div {
    min-width: 0;
  }

  div {
    width: 100%;
  }

  .ChoiceColumnItem {
    position: relative;
    margin-bottom: 8px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 7.5px 12px;
    border: 1px solid var(--cool-grey);
    border-radius: 6px;

    .ChoiceWrapper {
      min-width: 0;
      display: flex;
      align-items: center;
      flex-direction: row;
    }

    .WeightInput {
      margin-right: 25px;
    }

    .Rounded {
      width: 16px;
      height: 16px;
      border: 1.5px solid var(--dark-grey);
      border-radius: 100%;
      margin-right: 18px;
    }

    .Checkbox {
      width: 16px;
      height: 16px;
      border: 1.5px solid var(--dark-grey);
      border-radius: 5px;
      margin-right: 18px;
    }

    svg {
      position: absolute;
      right: 16px;
      top: 16px;
      margin-left: 15px;
      cursor: pointer;
    }
  }
}

.AddOptionBlock {
  width: 100%;
  margin-bottom: 8px;
  padding: 14px 12px;

  &.Bordered {
    border: 1px dashed var(--refined-teal);
    border-radius: 6px;
  }
}

.Fz_18 {
  font-size: 18px;
}

.Fz_14 {
  font-size: 14px;
}

.Information {
  margin-bottom: 16px;
}

.UploadContainer {
  width: 100%;
  padding: 0 24px;
}
