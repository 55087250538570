.Container {
  box-shadow: 0 8px 43px rgba(40, 165, 173, 0.1);
}

.Item {
  width: 100%;
  border-width: 0 0 0 1px;
  border-style: solid;
  border-color: var(--cool-grey);
  background-color: var(--white);
  transition-property: border-color, color, background-color;
  transition-duration: 0.3s;
  transition-timing-function: ease;

  & > * {
    width: 100%;
  }

  &:last-child {
    border-right-width: 1px;
  }

  &:focus-within {
    border-color: var(--refined-teal);
    color: var(--refined-teal);
    background-color: var(--light-teal);

    + .Item {
      border-left-color: var(--refined-teal);
    }
  }

  > span {
    width: 100%;
  }

  svg {
    flex-shrink: 0;
  }
}

.Title {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: var(--matte-black);
  background-color: transparent;

  @media screen and (max-width: 932px) {
    font-size: 10px;
    line-height: normal;
    padding: 8px;
  }
}
