@import '../../../../../../../../../../../../styles/mixins.scss';

.ChartInfo {
  padding: 12px;
  min-width: 0;

  &.Yellow {
    background-color: var(--ivory-2);
  }

  &.Blue {
    background-color: var(--twilight-blue);
  }

  h2 {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: var(--matte-black);
    margin-bottom: 4px;
  }

  .ChartInfoLabel {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: var(--dark-grey);
    display: flex;
    flex-direction: column;
    align-items: center;

    span:first-child {
      max-width: 100%;
      @include ellipsis;
    }

    span:not(:last-child) {
      border-bottom: 1px solid var(--cool-grey);
      padding-bottom: 8px;
    }

    span:not(:first-child) {
      padding-top: 8px;
    }
  }
}
