.TabsContainer {
  display: flex;
  width: 100%;
  margin-bottom: 18px;

  @media screen and (max-width: 932px) {
    margin-bottom: 24px;
    flex-direction: column;
    gap: 16px;
  }

  &.SpaceBetweenChildren {
    justify-content: space-between;
  }

  &.SpaceBetween {
    .Tabs {
      justify-content: space-between;
    }
  }

  &.Underline {
    .Tabs {
      .Tab {
        &.Active {
          border-bottom: 1px solid var(--refined-teal);
        }
      }
    }
  }

  &.FullWidth {
    .Tabs {
      width: 100%;
      gap: 0;
      .Tab {
        text-align: center;
        flex: 1;
        padding: 8px 0;

        &.Active {
          border-bottom: 1px solid var(--refined-teal);
        }
      }
    }
  }

  &.Center {
    .Tabs {
      width: 100%;
      justify-content: center;
    }
  }

  &.FontLarge {
    .Tabs {
      .Tab {
        font-size: 24px;
      }
    }
  }

  .Tabs {
    display: flex;
    align-items: center;
    gap: 24px;
    overflow-x: auto;

    .Tab {
      cursor: pointer;
      white-space: nowrap;
      font-weight: 700;
      font-size: 18px;
      line-height: 27px;
      letter-spacing: -1px;
      color: var(--matte-black);
      transition: 0.3s;

      @media screen and (max-width: 932px) {
        line-height: normal;
        font-size: 16px;
        padding-top: 5px;
        padding-bottom: 5px;
      }

      &.Active {
        color: var(--refined-teal);
      }
    }
  }

  .Controls {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      margin-left: 4px;

      @media screen and (max-width: 932px) {
        margin-left: 0;
      }
    }
  }
}
