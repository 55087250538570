.link {
  display: inline;
  position: relative;
}

.linkPopup {
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  background-color: var(--white);
  padding: 6px 10px;
  gap: 10px;
  border-radius: 6px;
  border: 1px solid var(--alto);
  width: fit-content;
  z-index: 1;
}

.popupWrapper {
  display: inline;
  position: relative;
}

.popup {
  position: absolute;
  left: 0;
  background-color: var(--white);
  padding: 10px;
  padding-top: 6px;
  border: 1px solid var(--alto);
  height: fit-content;
  z-index: 1;

  .popupWrapper {
    display: flex;
    gap: 4px;
    margin: 5px 2px;

    button {
      background: none;
    }
  }

  label {
    display: flex;
    align-items: center;
    input {
      margin-left: 2px;
      margin-right: 5px;
    }
  }
}

.button {
  background: none;
}

img {
  height: 15px;
}
