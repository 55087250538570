.Card {
  width: 100%;
  padding: 24px 32px;
  border-radius: 16px 0 0 16px;

  & + & {
    border-radius: 0 16px 16px 0;
  }

  &.Blue {
    border: 1px solid var(--lines);
    background-color: var(--twilight-blue);
  }

  &.Yellow {
    border: 1px solid var(--tusk);
    background-color: var(--ivory-2);
  }
}

.Title {
  padding-bottom: 4px;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  text-transform: uppercase;
  color: var(--matte-black);
}
