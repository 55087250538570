@import '../../../../../styles/mixins.scss';

.AccordionSummaryChildrenTitleContainer {
  display: flex;
  align-items: center;
  min-width: 0;

  .AccordionSummaryChildrenTitle {
    font-weight: 700;
    font-size: 18px;
    line-height: 27.4px;
    letter-spacing: -1px;
    padding-right: 1px;
    @include ellipsis;
  }

  .AccordionSummaryChildrenCount {
    font-weight: 400;
    font-size: 12px;
    line-height: 13.8px;
    letter-spacing: 0px;
    margin-left: 4px;
    margin-right: 4px;
  }
}

.PaginationContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
}

.MobileContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.PaginationContainer {
  margin-top: auto;
  margin-bottom: 64px;
  display: flex;
  justify-content: center;
}
