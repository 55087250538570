.Content {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.Header {
  width: 100%;
  height: 31px;
  padding: 10px 8px;
  position: relative;

  color: var(--dark-grey);
  border-bottom: 1px solid var(--lines);
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.HeaderTitle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ControlButton {
  display: flex;
  border: none;
  background-color: transparent;

  &:disabled {
    svg path {
      fill: var(--dark-grey);
    }
  }
}

.ScrolledContent {
  display: flex;
  position: relative;
  overflow-x: scroll;

  &:global(.swiper-slide) {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  &:global(.swiper-wrapper) {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }
}

.Loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ScrolledElement {
  width: 412px !important;
  min-width: 412px !important;
  border-right: 1px solid var(--lines);
  position: relative;
  display: flex;
  flex-direction: column;
}

.NoResults {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 46px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: var(--dark-grey);
}
