.SliderInput {
  width: 100%;
  padding: 12px;
  height: 44px;
  background: var(--white);
  border: 1px solid var(--cool-grey);
  border-radius: 8px;
  text-align: center;
  margin-bottom: 32px;
}

.SliderContainer {
  padding: 0 28px;
  padding-bottom: 10px;
}
