.SortButton {
  border-radius: 999px;
  transition: all 0.2s;

  &.Active {
    background-color: var(--oyster-bay);
  }

  .Arrow {
    margin-right: 4px;

    &.Sort {
      transform: rotate(180deg);
    }
  }

  &:hover {
    .Arrow path {
      fill: var(--refined-teal);
    }

    color: var(--refined-teal);
    border-color: var(--refined-teal);
  }
}
