.PreviewContainer {
  min-height: 146px;
  border-radius: 8px;
  cursor: pointer;
  overflow: hidden;
  text-align: center;

  @media screen and (max-width: 932px) {
    min-height: auto;
  }

  img {
    height: auto;
    width: auto;
    max-width: 500px;
    max-height: 400px;

    @media screen and (max-width: 932px) {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .EditContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background: var(--image-overlay);

    svg {
      path {
        fill: var(--white);
      }
    }

    span {
      margin-top: 18px;
      font-size: 16px;
      line-height: 18px;
      font-weight: 600;
      text-transform: uppercase;
      color: var(--white);
    }
  }
}
