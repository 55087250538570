.Tooltip {
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.TooltipContent {
  color: var(--matte-black);

  h1 {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    text-transform: uppercase;
    margin-bottom: 8px;
  }

  .Content {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }

  button {
    margin-top: 8px;
  }
}
