.ClearButton {
  padding: 16px;
  background: #ffe6e6;
  margin-left: 8px;
  border-radius: 8px;
}

.Icon {
  width: 13.5px;
  height: 13.5px;
}
