.Title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  height: 31px;
  padding: 0 16px;
  border-bottom: 1px solid var(--lines);
}

.Permissions {
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 96px;
  border-bottom: 1px solid var(--lines);

  &.Checkboxes {
    padding: 12px 5px;
    flex-direction: column;
    align-items: flex-start;
    height: auto;
  }
}
