.Dropzone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 13px;
  margin-bottom: 3px;
  transition: 0.3s;
  border-radius: 3px;
  border: 1px dashed transparent;

  &.Last {
    height: 60px;
  }

  &.LastNoEmpty {
    height: 13px;
  }

  &.CanDrop {
    border-color: var(--dark-grey);
  }

  &.Active {
    height: 60px;
    border-color: var(--refined-teal);
  }
}

.EmptyBox {
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--dark-grey);
}
