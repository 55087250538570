.SelectedContainer {
  padding: 16px;
  border-top: 1px solid #d3eff1;
  background-color: var(--white);
  width: 100%;
  position: fixed;
  bottom: -69px;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  visibility: hidden;
  transition: all 0.3s ease;
  z-index: 999;

  &.Active {
    visibility: visible;
    bottom: 0;
  }
}
