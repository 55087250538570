.CardHeader {
  width: 100%;

  &.Intro {
    text-align: center;

    .Title {
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      line-height: normal;
      letter-spacing: -2px;
      color: var(--matte-black);
      white-space: pre-line;
    }

    .Subtitle {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: normal;
      color: var(--dark-grey);
      white-space: pre-line;
    }
  }

  .Title {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: -1px;
    color: var(--matte-black);
    margin-bottom: 8px;
    white-space: pre-line;
  }

  .Subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: var(--dark-grey);
    white-space: pre-line;
  }
}
