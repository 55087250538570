.Container {
  margin-top: 24px;

  .QuestionLabelContainer {
    margin-bottom: 8px;
    min-height: 16px;
    height: auto;

    .QuestionLabel {
      font-size: 16px;
      font-weight: 600;
      line-height: 18px;
      text-transform: uppercase;
      color: var(--matte-black);
    }
  }
}
