@import 'styles/mixins';

.Container {
  width: 256px;
  background: var(--matte-black);
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  border-radius: 8px;
  box-shadow: var(--block-shadow);

  .Title {
    margin-bottom: 24px;
    font-size: 14px;
    font-weight: 600;
    color: var(--matte-black);
    text-transform: uppercase;
    padding: 16px;
    padding-top: 16px;
    padding-bottom: 0;
  }

  .ScrollableContainer {
    margin-bottom: 24px;
    flex-grow: 1;

    .User {
      padding: 8px 12px;
      font-size: 14px;
      border: 1px solid var(--cool-grey);
      border-radius: 6px;
      @include ellipsis();

      &:not(:last-child) {
        margin-bottom: 4px;
      }
    }
  }

  .ButtonContainer {
    margin-top: auto;
    padding: 16px;
    padding-top: 0;
    width: 100%;
  }
}
