@import '../../../styles/mixins.scss';

.AssessmentDetailsPage {
  width: inherit;

  @media screen and (max-width: 932px) {
    padding: 0 16px;
  }
}

.AssessmentInfoContainer {
  .AssessmentInfo {
    flex: 1;
    &:not(:last-of-type) {
      margin-bottom: 24px;
    }

    > span {
      display: block;
      margin-bottom: 6px;
      font-weight: 500;
      line-height: 14px;
      color: var(--dark-grey);

      font-size: 12px;
      font-weight: 400;
      line-height: 15.6px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: var(--matterhorn);
    }

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: var(--matte-black);
      font-size: 14px;
      font-weight: 400;
      line-height: 19.6px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }
  }
}

.IconContainer {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  background: var(--light-teal);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;

  svg {
    width: 36px;
    height: 40px;

    path {
      fill: var(--refined-teal);
    }
  }
}

.TagsContainer {
  //   &:not(:last-child) {
  //     margin-bottom: 24px;
  //   }

  .Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    h3 {
      font-weight: 700;
      font-size: 18px;
      line-height: 27px;
      letter-spacing: -1px;
      color: var(--matte-black);
      //styleName: 18_Bold;
      font-size: 18px;
      font-weight: 700;
      line-height: 20.7px;
      letter-spacing: -1.5px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }

    svg {
      width: 11.5px;
      height: 11.5px;
    }
  }
}

.PageTitle {
  display: flex;
  align-items: center;

  @media screen and (max-width: 932px) {
    width: 100%;
    justify-content: space-between;
  }

  button {
    flex-shrink: 0;
  }

  h3 {
    margin-right: 14px;
    font-weight: 700;
    font-size: 28px;
    line-height: 43px;
    letter-spacing: -1px;
    padding-right: 1px;
    max-width: 500px;
    @include ellipsis;
  }
}

.PageTitleActions {
  display: flex;
}

.SearchContainer {
  flex: 1;
}

.SortFilterByContainer {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
}

.FilterByButtonContainer {
  position: relative;
}

.FilterByBlockContainer {
  position: absolute;
  top: 40px;
  right: 0;
  z-index: 1000;
}
