.Container {
  width: 100%;

  &.Scrollable {
    padding-right: 6px !important;
  }

  .Wrapper {
    width: 100%;
    min-height: 45px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: var(--refined-teal) var(--lines);

    &.Scrollable {
      padding-right: 6px;
    }

    &.Visible {
      overflow: visible;
    }

    &.Yellow {
      scrollbar-color: var(--chartreuse) var(--tusk);

      &::-webkit-scrollbar-track {
        background: var(--tusk);
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--chartreuse);
      }
    }

    &:not(:last-child) {
      margin-right: 8px;
    }

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 1000px;
      background: var(--lines);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 100px;
      background-color: var(--refined-teal);
    }
  }
}
