.Container {
  .Dots {
    display: flex;
    justify-content: center;
    gap: 8px;

    button {
      &.Active {
        background-color: var(--matte-black);
      }

      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: var(--dark-grey);
    }
  }
}
