@import '../../../../../../styles/mixins.scss';

.Container {
  width: 100%;
  height: 100%;
  padding: 20px 32px 32px;
  border-radius: 8px;
  background: var(--white);
  box-shadow: var(--block-shadow);

  @media screen and (max-width: 932px) {
    padding: 24px 16px;
  }
}

.Title {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: var(--matte-black);
  @include ellipsis;
}
