.Line {
  height: 1px;
  width: calc(100% - 32px);
  background-color: var(--lines);
  margin: 22px auto;

  &.Yellow {
    background-color: var(--tusk);
  }
}
