.custom-tag {
  &.rs-tag {
    background-color: var(--light-teal);
    padding: 4px 8px;
    border-radius: 1000px;
    display: flex;
    align-items: center;
    gap: 16px;

    & + .rs-tag {
      margin-left: 0;
    }

    .rs-tag-text {
      font-size: 12px;
      font-weight: 400;
      line-height: 15.6px;
      color: var(--refined-teal);
    }

    .rs-tag-icon-close {
      color: var(--refined-teal);
      position: relative;
      padding: 0;
      height: 8px;
      width: 8px;
      line-height: 0;
    }

    .rs-icon {
      height: 8px;
      width: 8px;
    }
  }

  &.rs-tag-closable {
    &.rs-tag-md {
      padding-right: 8px;
    }
  }
}
