.ScurveContainer {
  display: flex;
}

.ChartWrapper {
  width: 100%;
  min-width: 0;
  padding: 0 16px 20px;
  border-radius: 8px;
  box-shadow: var(--block-shadow);
  background: var(--white);

  @media screen and (max-width: 932px) {
    padding: 0 8px 16px;
  }

  .DistributionContainer {
    display: flex;
    margin-top: 24px;
    border: 1px solid var(--refined-teal);

    @media screen and (max-width: 932px) {
      align-items: stretch;
    }

    > div {
      flex: 1;
      padding: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      min-width: 0;
    }

    > div:not(:last-child) {
      border-right: 1px solid var(--refined-teal);
    }
  }
}

.ChildCharts {
  margin-top: 24px;

  .Title {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -1px;
    color: var(--matte-black);
  }
}

.MobileChildChartsContainer {
  position: relative;
}
