.AssessmentListPage {
  width: inherit;
  min-width: 0;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 932px) {
    padding: 0 16px;
  }
}
