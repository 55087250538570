.TakeAssessment {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  @media screen and (max-width: 932px) {
    width: 100%;
    padding: 0 16px;
  }

  .AssessmentTitle {
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 43px;
    letter-spacing: -2px;
    color: var(--matte-black);
    text-align: center;
    margin-bottom: 57px;

    @media screen and (max-width: 932px) {
      line-height: normal;
      margin-bottom: 24px;
    }
  }

  .AssessmentBody {
    width: 845px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 932px) {
      width: 100%;
    }

    .AssessmentCard {
      width: 749px;
      min-height: 300px;
      margin-bottom: 100px;

      display: flex;
      align-items: center;
      flex-direction: column;

      .IncompleteAssessmentBody {
        width: 100%;
        padding: 24px 16px;
        border: 1px solid var(--lines);
        background: var(--white);
        border-radius: 8px;

        h2 {
          font-style: normal;
          font-weight: 700;
          font-size: 28px;
          line-height: normal;
          letter-spacing: -2px;
          color: var(--matte-black);
          text-align: center;
          margin-bottom: 12px;
        }

        .IncompleteAssessmentCard {
          display: flex;
          align-items: center;
          cursor: pointer;

          .IncompleteAssessmentCardOrder {
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 37px;
            letter-spacing: -1px;
            color: var(--matte-black);
            margin-right: 16px;
          }
          .IncompleteAssessmentCardText {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.5px;
            color: var(--matte-black);
          }
        }
      }

      @media screen and (max-width: 932px) {
        width: 100%;
        margin-bottom: 0;
      }
    }
  }

  .StepsContainer {
    width: 100%;

    @media screen and (max-width: 932px) {
      margin-top: 24px;
    }
  }
}

.ExpiredContainer {
  width: 495px;
  display: flex;
  justify-content: center;
  align-items: center;
}
