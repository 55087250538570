.Editable {
  border: 1px solid var(--cool-grey);
  border-radius: 8px;
  min-height: 200px;
  padding: 10px;

  &.Disabled {
    border: none;
    color: var(--dark-grey);
    background-color: var(--black-squeeze);
  }

  &.RenderOnly {
    border: none;
    border-radius: 0;
    min-height: 0;
    padding: 0;
  }
}
