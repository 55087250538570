@import '../../../styles/mixins.scss';

.SearchBar {
  margin-bottom: 16px;
}

.DiagnosticName {
  font-size: 16px;
  font-weight: 700;
  line-height: 18.4px;
  letter-spacing: -0.02em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-bottom: 16px;
  display: block;
}

.Description {
  font-size: 14px;
  font-weight: 400;
  line-height: 130%;
  text-align: center;
  margin: auto;
  margin-bottom: 24px;
  color: var(--dark-grey);
}

.LoaderContainer {
  min-height: 51px;
}

.Title {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: var(--refined-teal);
  margin-right: 10px;
  @include ellipsis;
}

.Count {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  border-radius: 1000px;
  background-color: var(--lines);
  padding: 2px;
  min-width: 20px;
  text-align: center;
}

.TagsContainer {
  margin-bottom: 16px;
}

.Accordions {
  > div {
    display: flex;
    align-items: center;
    span {
      font-size: 14px;
    }
  }
  //   > div {
  //     border-top: 1px solid var(--lines);
  //     border-radius: 0 !important;

  //     &:last-child {
  //       border-bottom: 1px solid var(--lines);
  //     }
  //   }
}

.Divider {
  background-color: #eaeaea;
  width: 100%;
  height: 1px;
  margin-bottom: 16px;
}

.Radio {
  label {
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
  }
}
