@import '../../../../../../styles/mixins.scss';

.Element {
  display: flex;
  align-items: center;
  font-weight: 400;
  line-height: 16px;
  z-index: 20;
  cursor: pointer;
  padding-left: 13px;
  margin-bottom: 4px;
  background: var(--white);
  border-radius: 8px;
  border: 1px solid var(--cool-grey);
  border-left: 8px solid var(--cool-grey);

  .Path {
    margin-right: 23px;
  }

  .Name {
    margin-right: 20px;
    @include ellipsis;
  }

  .Description {
    flex: 1;
  }

  .Actions {
    width: 256px;
    display: flex;
    align-items: center;

    a {
      text-decoration: none;
    }
  }

  &.Active {
    border: 1px solid var(--refined-teal);
    border-left: 8px solid var(--refined-teal);
  }

  &.Checked {
    background: var(--light-teal);
    border-color: var(--refined-teal);
  }

  &.Lg {
    font-size: 16px;
    height: 66px;

    .Name {
      width: 150px;
    }
  }

  &.Md {
    font-size: 14px;
    height: 48px;

    .Name {
      width: 125px;
    }
  }

  &.Sm {
    font-size: 12px;
    height: 38px;

    .Name {
      width: 105px;
    }
  }

  .FakeCaret {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }

  .Caret {
    margin-right: 16px;
    transition: 0.2s;
    flex-shrink: 0;
    transform: rotate(180deg);

    &.Active {
      transform: rotate(0deg);
    }
  }
}
