@import '../../styles/mixins.scss';

.Container {
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  min-width: 0;

  &.Column {
    flex-direction: column;
    align-items: start;
    gap: 12px;

    .Right {
      width: 100%;
      margin-left: 0;
    }
  }

  .Left {
    flex: 1;
    display: flex;
    align-items: center;
    min-width: 0;

    .Icon {
      flex-shrink: 0;
    }

    .Title {
      min-width: 0;
      color: var(--matte-black);
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -2px;
      padding-right: 2px;
      margin-left: 16px;
      @include ellipsis;
    }
  }

  .Right {
    margin-left: 16px;
  }
}
