.DeleteButton {
  display: flex;
  align-items: center;
  border: none;
  background: transparent;

  .Title {
    color: var(--red);
    text-transform: uppercase;
    margin-left: 8px;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
  }

  .Lg {
    svg {
      width: 18.5px;
      height: 20.25px;
    }
  }

  .Md {
    svg {
      width: 15px;
      height: 16.5px;
    }
  }

  .Sm {
    svg {
      width: 11px;
      height: 12px;
    }
  }
}
