@import '../../styles/mixins.scss';

.MenuWrapper {
  width: 100%;
  max-width: 290px;

  margin-right: 20px;
  position: relative;
  padding-right: 11px;
  transition: all 0.3s ease;

  @media screen and (max-width: 1180px) {
    height: calc(100dvh - 73.5px);
    transform: translateX(-100%);
    visibility: hidden;
    pointer-events: none;
    position: fixed;
    top: 74.5px;
    left: 0;
    z-index: 1000;
    opacity: 0.4;
  }

  &.MobileOpen {
    @media screen and (max-width: 1180px) {
      visibility: visible;
      pointer-events: all;
      opacity: 1;
      background-color: var(--white);
      width: 100%;
      min-width: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      padding-right: 0px;
      transform: translateX(0);
    }

    .Menu {
      @media screen and (max-width: 1180px) {
        min-width: 100%;
        box-shadow: unset;
        overflow-y: auto;
      }
    }
  }

  &.Disabled {
    display: none;

    @media screen and (max-width: 1180px) {
      display: flex;
    }
  }

  &.Hidden {
    max-width: 66px;

    .Menu {
      .Link {
        .LinkContent {
          .LinkTitle {
            transition:
              all 1ms linear,
              opacity 0.6s,
              background 0.2s,
              color 0.2s !important;
            visibility: hidden;
            opacity: 0;
            pointer-events: none;
            max-height: 0;
          }
        }
      }
    }
    .MenuToggle svg {
      transform: rotate(180deg);
    }
  }

  .Menu {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    border-radius: 8px;
    box-shadow: var(--block-shadow);

    @media screen and (max-width: 1180px) {
      border-radius: 0;
    }

    .Link {
      width: 100%;
      min-height: 56px;
      position: relative;
      overflow: hidden;
      text-decoration: none;
      transition: all 0.3s;
      display: flex;
      align-items: center;

      .LinkContent {
        width: 100%;
        height: 100%;
        padding-left: 17px;

        display: flex;
        align-items: center;
        justify-content: flex-start;

        .LinkIcon {
          width: 24px;
          height: 24px;
          margin-right: 12px;

          svg path {
            fill: var(--menu-text);
          }
        }

        .LinkTitle {
          color: var(--menu-text);
          text-transform: uppercase;
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
          transition:
            all 1ms linear 0.3s,
            opacity 0.6s,
            background 0.2s,
            color 0.2s;
          visibility: visible;
          opacity: 1;
          max-height: 500px;
          padding: 10px 0;
          padding-right: 20px;
          @include breakText();
        }
      }

      &.ActiveLink {
        background: var(--menu-gradient);

        &::before {
          opacity: 1;
        }

        .LinkContent {
          .LinkTitle {
            color: var(--white);
          }

          .LinkIcon {
            svg path {
              fill: var(--white);
            }
          }
        }
      }

      &:hover {
        background: var(--menu-gradient);

        .LinkContent {
          .LinkTitle {
            color: var(--white);
          }

          .LinkIcon {
            svg path {
              fill: var(--white);
            }
          }
        }
      }
    }
  }

  .MenuToggle {
    cursor: pointer;

    width: 24px;
    height: 24px;
    background: var(--white);
    border: 1px solid var(--lines);
    border-radius: 50%;

    right: 0;
    top: 16px;
    position: absolute;

    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 1180px) {
      display: none;
    }

    .MenuToggle svg {
      transition: all 0.3s;
    }
  }

  .SignOutButton {
    margin-top: auto;
    margin-bottom: 16px;
    justify-content: flex-start;
    background-color: transparent;
    align-items: center;
    padding: 16px 32px 16px 16px;
    display: none;

    @media screen and (max-width: 1180px) {
      display: flex;
    }

    span {
      margin-left: 12px;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;
    }
  }
}
