.Item {
  .Name {
    text-transform: uppercase;
    font-weight: 600;
    color: var(--matte-black);
  }

  .Sections {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
  }

  &.Large {
    margin-bottom: 24px;

    .Name {
      font-size: 16px;
      margin-bottom: 12px;
    }
  }

  &.Medium {
    &:not(:last-child) {
      margin-bottom: 16px;
    }

    .Name {
      font-size: 12px;
      margin-bottom: 8px;
    }
  }
}
