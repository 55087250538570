@import '../../styles/mixins.scss';

.Title {
  font-size: 28px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
  letter-spacing: -1px;
  margin-bottom: 8px;
  color: var(--refined-teal);
  @include breakText();
}

.Description {
  font-size: 14px;
  font-weight: 400;
  line-height: 130%;
  text-align: center;
  margin-bottom: 24px;
  color: var(--dark-grey);
  @include breakText();
}
