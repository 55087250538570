.SortButton {
  flex-shrink: 0;
  user-select: none;
  height: 32px;
  cursor: pointer;
  background: var(--white);
  padding: 0 12px 0 8px;
  border-radius: 1000px;
  border: 1px solid var(--cool-grey);

  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: var(--dark-grey);

  display: flex;
  align-items: center;
  justify-content: center;

  transition: 0.3s;

  .Arrow {
    margin-right: 4px;

    &.Sort {
      transform: rotate(180deg);
    }
  }

  &:hover {
    .Arrow path {
      fill: var(--refined-teal);
    }

    color: var(--refined-teal);
    border-color: var(--refined-teal);
  }
}
