.Tooltip {
  cursor: pointer;
}

.TooltipContent {
  width: 172px;

  .Container {
    padding: 12px 16px;
    color: var(--matte-black);

    &.Yellow {
      background: var(--ivory-2);

      > span {
        border: 1px solid var(--tusk);
      }
    }

    &.Blue {
      background: var(--twilight-blue);

      > span {
        border: 1px solid var(--lines);
      }
    }

    > span {
      border: 1px solid var(--lines);
      border-radius: 8px;
      padding: 6px 10px;
      font-weight: 600;
      font-size: 14px;
      line-height: 140%;
      margin-bottom: 10px;
      display: inline-block;
    }

    .Body {
      h2 {
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
        text-transform: uppercase;
        margin-bottom: 7px;
      }

      .Content {
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
        }
      }
    }
  }

  .ButtonContainer {
    background-color: var(--white);
    padding: 10px 16px 12px 16px;

    button {
      min-width: 80px;
      height: 32px;
      padding: 0px 16px;
      font-size: 12px;
    }
  }
}
