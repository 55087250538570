.PermissionPage {
  width: inherit;
}

.PermissionsWrapper {
  width: 100%;
  height: auto;
  margin-bottom: 100px;
  display: flex;

  .Titles {
    width: 279px;
    height: 100%;
    border-right: 1px solid var(--lines);
    display: flex;
    flex-direction: column;

    .Header {
      height: 62px;
      color: var(--dark-grey);
      border-bottom: 1px solid var(--lines);
      display: flex;
      align-items: flex-end;

      .Title {
        margin-bottom: 8px;
      }
    }

    .TitleElement {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 96px;
      border-bottom: 1px solid var(--lines);
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      text-transform: uppercase;

      svg {
        margin-right: 8px;
      }

      &.Checkboxes {
        height: 145px;
        align-items: flex-end;
        padding-bottom: 27px;
      }
    }
  }
}
