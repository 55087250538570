@import '../../../../../../styles/mixins.scss';

.Container {
  display: flex;
  align-items: center;
  gap: 8px;

  @media screen and (max-width: 1180px) {
    &:has(:global(.MiddleLevelsNestedContainer)) {
      margin-bottom: 78px;
    }
  }

  .ArrowIcon {
    flex-shrink: 0;
  }

  :global {
    .rs-picker-disabled {
      opacity: 0.9;
    }

    .rs-picker-toggle {
      border-radius: 6px;
      box-shadow: var(--header-shadow-1) !important;
      border: 1px solid transparent !important;

      width: 200px;
      height: 32px;

      @media screen and (max-width: 1180px) {
        width: calc((100dvw - 112px) / 3);
      }

      &:hover {
        border: 1px solid var(--refined-teal) !important;
      }

      .rs-stack {
        width: 100%;
      }

      &.rs-picker-toggle-active {
        border: 1px solid var(--refined-teal) !important;
        box-shadow: var(--header-shadow-2) !important;
      }

      &.rs-btn {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .rs-picker-toggle-value {
          width: 158px;
          font-weight: 400;
          font-size: 12px;
          line-height: 140%;
          color: var(--refined-teal) !important;

          @media screen and (max-width: 1180px) {
            width: auto;
            max-width: 100%;
          }

          @include ellipsis;
        }

        .rs-picker-toggle-caret {
          top: 5px !important;

          path {
            fill: var(--refined-teal);
          }
        }
      }
    }

    .rs-picker-toggle-wrapper:last-child {
      .rs-picker-toggle {
        margin-right: 0;
      }
    }
  }
}
