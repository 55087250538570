@import '../../../../../../../styles/mixins.scss';

.Container {
  height: 100%;

  .MobileContentContainer {
    padding: 0 16px;
    height: 100%;
  }

  .Form {
    width: 100%;
    overflow: hidden;

    .Label {
      margin-right: 0;
      margin-left: 0;
      padding: 7px 11px;
      border: 1px solid var(--cool-grey);
      border-radius: 6px;
      color: var(--refined-teal);
      background-color: var(--white);
      transition: 0.3s ease-in-out background-color;

      &:has(.Radio input:focus-visible) {
        outline: -webkit-focus-ring-color auto 1px;
      }

      &.Checked {
        border-color: var(--refined-teal);
        background-color: var(--light-teal);
      }

      & + .Label {
        margin-top: 4px;
      }

      .Typography {
        width: 100%;
        font-weight: 400;
        font-size: 14px;
        line-height: 1;
        letter-spacing: initial;
      }
    }

    .TitleContainer {
      overflow: hidden;

      .Title {
        max-width: 100%;
        display: block;
        color: var(--refined-teal);
        @include ellipsis;
      }
    }

    .Radio {
      position: fixed;
      opacity: 0;
      color: var(--dark-grey);

      & :global .MuiSvgIcon-root {
        font-size: 1rem;
      }

      &:global.Mui-checked {
        color: var(--refined-teal);
      }
    }
  }

  .Image {
    width: 34px;
    height: 34px;
    border-radius: 50%;
  }

  .CardContentHeader {
    padding: 0px 28px;
    margin-bottom: 8px;

    span {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: var(--dark-grey);
    }
  }
}
