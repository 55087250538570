.label-container {
  display: flex;
  justify-content: space-between;

  .label-text {
    display: inline-flex;
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
    color: var(--matte-black);
    text-transform: uppercase;
    margin-bottom: 4px;

    &.large {
      font-size: 16px;
      line-height: 18px;
      margin-bottom: 8px;
    }
  }

  .error-message {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: var(--red);
  }
}

.custom-tag-tree-picker {
  &.rs-picker-toggle-wrapper {
    display: flex;
  }
  &.rs-picker-default {
    &:not(.rs-picker-disabled) {
      .rs-picker-toggle:hover {
        border-color: var(--light-teal);

        &.rs-picker-toggle-active {
          box-shadow: 0 0 0 3px var(--light-teal);
          border-color: var(--light-teal);
        }
      }
      .rs-picker-toggle-active {
        box-shadow: 0 0 0 3px var(--light-teal);
        border-color: var(--light-teal);
      }

      .rs-picker-toggle:focus {
        border-color: var(--light-teal);
      }
    }

    .rs-picker-toggle {
      height: 44px;
      border: 1px solid var(--cool-grey);

      .rs-picker-toggle-placeholder {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: var(--dark-grey);
      }

      &.rs-btn {
        padding-bottom: 11px;
        padding-top: 11px;

        .rs-picker-toggle-caret {
          top: 11px;
        }
      }
    }

    &.invalid {
      .rs-picker-toggle {
        border: 1px solid var(--red);

        &:hover {
          border: 1px solid var(--red);

          &.rs-picker-toggle-active {
            box-shadow: 0 var(--red);
            border-color: var(--red);
          }
        }
        &:focus {
          border: 1px solid var(--red);
        }

        .rs-picker-toggle-placeholder {
          color: var(--red);
        }
      }

      .rs-picker-toggle-active {
        box-shadow: 0 var(--red);
        border-color: var(--red);
      }
    }
  }

  .rs-picker-toggle-caret {
    color: var(--refined-teal);
  }
}

.custom-tag-tree-picker-menu {
  &.rs-picker-menu {
    margin-top: 6px;
    border: 1px solid var(--cool-grey);
    box-shadow: none;
    border-radius: 8px;
    max-height: 176px;
    z-index: 1001;

    .rs-check-tree {
      padding: 0;

      &::-webkit-scrollbar {
        width: 4px;
        background-color: var(--lines);
        border-radius: 1000px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 100px;
        background: var(--refined-teal);
      }

      &::-webkit-scrollbar-button {
        display: none;
      }

      .rs-check-tree-node-expand-icon-wrapper {
        display: none;
      }

      .rs-check-tree-node {
        padding-left: 0;

        &:has(.rs-checkbox-checked),
        &:has(.rs-check-item:hover),
        &:has(.rs-check-item-focus) {
          background: var(--light-teal);
        }

        .rs-check-item {
          &:hover,
          &.rs-check-item-focus {
            background-color: var(--light-teal);
          }

          .rs-checkbox-checker {
            > label {
              padding: 12px 12px 12px 25px;
            }
          }
        }

        .rs-checkbox-checked {
          background: #d5eff1;

          .rs-checkbox-checker {
            label {
              font-weight: 400;
              color: var(--matte-black);
            }

            .rs-checkbox-wrapper {
              .rs-checkbox-inner {
                &:before {
                  border-radius: 5px;
                  border-color: var(--refined-teal);
                  background-color: var(--refined-teal);
                }

                &:after {
                  border-width: 0 1px 1px 0;
                  width: 4px;
                  height: 6px;
                  margin-top: 4px;
                  margin-left: 6px;
                }
              }
            }
          }
        }

        .rs-checkbox-checker {
          label {
            color: var(--dark-grey);
            padding-left: 25px;

            &::before {
              display: none;
            }

            .rs-checkbox-wrapper {
              left: 0px;
              top: 14px;

              .rs-checkbox-inner {
                &:before {
                  border-radius: 5px;
                  border-width: 1.5px;
                  border-color: var(--dark-grey);
                }
              }
            }
          }
        }
      }
    }
  }
}
