.Container {
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;
  overflow: hidden;
}

.CalculationHelper {
  width: 100%;
  height: 0;
  overflow: hidden;
}

.TagsContainer {
  display: inline-flex;
  gap: 4px;
}

.OverflowLabel {
  padding-left: 4px;
  font-weight: 400;
  font-size: 10px;
  line-height: 21px;
  color: var(--refined-teal);
}
