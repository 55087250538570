.Page {
  width: 100%;

  @media screen and (max-width: 932px) {
    padding: 0 16px;
    display: flex;
    flex-direction: column;
  }

  .ComparisonHeaderInfo {
    margin-bottom: 18px;
  }

  .NoResults {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 80px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: var(--dark-grey);
  }
}

.Section {
  margin-bottom: 48px;
}

.SectionTitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: -1px;
  color: var(--matte-black);
}

.SectionLabel {
  margin-top: 10.5px;
  display: flex;
  align-items: center;

  > span {
    margin-right: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: var(--matte-black);
    min-width: max-content;
  }
}

.SectionContent {
  margin-top: 8px;
}

.SelectionContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 48px;

  > div {
    flex: 1;

    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}

.HeaderInfoContainer {
  margin-left: 16px;

  @media screen and (max-width: 932px) {
    margin-left: 0px;
  }
}
