.GridContainer {
  margin-bottom: 24px;

  .FormRow {
    &.ChildRow {
      &:not(:last-child) {
        padding-bottom: 24px;
        border-bottom: 1px solid var(--cool-grey);
      }
    }
  }
}
