.MobileCard {
  display: flex;
  flex-direction: column;
  gap: 16px;
  box-shadow: var(--mobile-card-shadow);
  padding: 16px;
  border-radius: 8px;

  &.VariantOrganization,
  &.VariantGroup,
  &.VariantSubgroup,
  &.VariantUser {
    background: var(--white);
    box-shadow: none;

    .Container {
      .Row {
        .OrdinalContainer {
          .Value {
            color: var(--matte-black);
          }
        }
      }
    }
  }

  &.VariantOrganization {
    border-top: 1px solid var(--refined-teal);
    border-right: 1px solid var(--refined-teal);
    border-bottom: 1px solid var(--refined-teal);
    border-left: 4px solid var(--refined-teal);
  }

  &.VariantGroup {
    border-top: 1px solid var(--orange);
    border-right: 1px solid var(--orange);
    border-bottom: 1px solid var(--orange);
    border-left: 4px solid var(--orange);
  }

  &.VariantSubgroup {
    border-radius: 8px;
    border-top: 1px solid var(--chartreuse);
    border-right: 1px solid var(--chartreuse);
    border-bottom: 1px solid var(--chartreuse);
    border-left: 4px solid var(--chartreuse);
  }

  &.VariantUser {
    border: 1px solid var(--cool-grey);
  }

  &.VariantClear {
    box-shadow: none;
    padding: 0;
    border-radius: 0px;
  }

  &.VariantSelected,
  &.FullSelected {
    border: 2px solid var(--refined-teal);
  }

  &.FullSelected {
    background-color: var(--light-teal);
  }

  &.VariantError {
    border: 2px solid var(--red);
  }

  &:has(.ErrorContainer) {
    border: 2px solid var(--red);
  }

  .Container {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .Row {
      display: flex;
      gap: 16px;

      &:has(> .OrdinalContainer) {
        gap: 24px;
      }

      .InfoContainer {
        display: flex;
        flex-direction: column;
        flex: 1;
        gap: 6px;
        min-width: 0;

        .Label {
          color: var(--dark-grey);
          font-family: Arimo;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .Value {
          overflow: hidden;
          color: var(--matte-black);
          text-overflow: ellipsis;
          font-family: Arimo;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }

      .OrdinalContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .Label,
        .Value {
          color: var(--dark-grey);
          font-size: 12px;
          font-weight: 400;
          line-height: normal;
        }
      }

      .ActionsContainer {
        display: flex;
        flex-direction: column;
        gap: 6px;
        width: 100%;

        .Label {
          color: var(--dark-grey);
          font-size: 12px;
          font-weight: 400;
          line-height: normal;
        }

        .Actions {
          display: grid;
          grid-auto-flow: column;
          grid-auto-columns: 1fr;
          flex-wrap: wrap;
          width: 100%;
          gap: 8px;
          align-items: center;

          > * {
            flex: 1;
          }
        }
      }

      .ErrorContainer {
        color: red;
      }
    }
  }
}
