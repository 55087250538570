.AssessmentPage {
  width: inherit;

  @media screen and (max-width: 932px) {
    padding: 0 16px;
  }

  .ButtonText {
    margin-left: 8px;
    margin-right: 16px;
  }

  .Caret {
    transition: all 0.2s ease;
    transform: rotate(180deg);

    &.Active {
      transform: rotate(0deg);
    }
  }

  .Blocks {
    display: flex;
    margin-bottom: 105px;
  }

  .Compare {
    .CompareHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h3 {
        font-weight: 700;
        font-size: 18px;
        line-height: 27px;
        letter-spacing: -1px;
        color: var(--matte-black);
        margin-bottom: 8px;
      }
    }
  }
}

.Container {
  background: var(--white);
  box-shadow: var(--modal-shadow);
  border-radius: 8px;
  flex: 1;
  opacity: 1;
  transition: opacity 0.3s ease;
  padding-bottom: 16px;

  &.Hidden {
    visibility: hidden;
    opacity: 0;
  }

  .SearchContainer {
    width: 55%;
  }
}

.SearchIcon {
  color: var(--dark-grey);
}

.CompareIcon {
  width: 15px;
  height: 20px;
  margin: 0 12.5px;
  color: var(--refined-teal);
}
