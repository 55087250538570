.Container {
  .TitleContainer {
    display: flex;
    margin-bottom: 12px;

    @media screen and (max-width: 932px) {
      align-items: center;
    }

    .DashboardTooltipContainer {
      display: flex;
      align-items: center;
      flex-shrink: 0;

      &:has(+ .ButtonContainer) {
        margin-right: 8px;
      }
    }

    .Title {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      margin-right: 8px;
      text-transform: uppercase;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      color: var(--matte-black);
    }

    &.Lg {
      .Title {
        font-size: 14px;
      }
    }

    &.Sm {
      margin-bottom: 9px;

      .Title {
        font-size: 12px;
      }
    }

    .ButtonContainer {
      @media screen and (max-width: 932px) {
        margin-left: auto;
      }
    }
  }

  .CardInfo {
    img {
      border-radius: 50%;
      width: 24px;
      height: 24px;
      margin-right: 12px;
      object-fit: cover;
    }
  }

  .AccelerantsContainer {
    display: flex;

    .ButtonContainer {
      min-width: 100px;
      margin-left: 20px;
    }
  }
}
