.Element {
  display: flex;
  align-items: center;
  font-weight: 400;
  line-height: 16px;
  z-index: 20;
  cursor: pointer;
  padding-left: 13px;
  margin-bottom: 4px;
  background: var(--white);
  border-radius: 8px;

  .Path {
    margin-right: 23px;
  }

  .Name {
    width: 340px;
  }

  .Description {
    flex: 1;
  }

  .Actions {
    width: 354px;
    display: flex;
    align-items: center;

    a {
      text-decoration: none;
    }
  }

  &.Lg {
    font-size: 16px;
    height: 66px;
    border: 1px solid var(--refined-teal);
    border-left: 8px solid var(--refined-teal);

    &.Active {
      border: 1px solid var(--refined-teal);
      border-left: 8px solid var(--refined-teal);
    }

    .Name {
      width: 340px;
    }
  }

  &.Md {
    font-size: 14px;
    height: 48px;
    border: 1px solid var(--orange);
    border-left: 8px solid var(--orange);

    .Name {
      width: 309px;
    }

    .Actions {
      width: 297px;
    }
  }

  &.Sm {
    font-size: 12px;
    height: 38px;
    border: 1px solid var(--chartreuse);
    border-left: 8px solid var(--chartreuse);

    &.Active {
      border: 1px solid var(--chartreuse);
      border-left: 8px solid var(--chartreuse);
    }

    .Name {
      width: 283px;
    }

    .Actions {
      width: 326px;
    }
  }

  .FakeCaret {
    width: 16px;
    height: 16px;
    margin-right: 16px;
  }

  .Caret {
    margin-right: 16px;
    transition: 0.2s;
    transform: rotate(180deg);

    &.Active {
      transform: rotate(0deg);
    }
  }
}
