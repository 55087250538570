.NothingFoundContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  height: 100%;

  &.Center {
    margin-top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  span {
    color: var(--dark-grey);
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
  }
}
