.CustomInput {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 16px;

  .LabelContainer {
    display: flex;
    justify-content: space-between;
  }

  .InputLabels {
    display: flex;
    align-items: center;
    justify-content: space-between;

    height: 16px;
    margin-bottom: 5px;

    .Required {
      color: var(--red);
      margin-left: 3px;
    }

    .InputLabel {
      font-size: 12px;
      font-weight: 500;
      line-height: 14px;

      color: var(--dark-grey);
    }

    .InputErrors {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: var(--red);
    }
  }

  .Input {
    resize: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;

    padding: 12px;
    background: var(--white);

    border-radius: 8px;
    border: 1px solid var(--cool-grey);

    &.Password {
      padding-right: 44px;
    }

    &.Invalid {
      border-color: var(--red);
    }

    &.Valid {
      border-color: var(--refined-teal);
    }

    &::placeholder {
      color: var(--dark-grey);
    }

    &:disabled {
      border: none;
      color: var(--dark-grey);
      background-color: var(--black-squeeze);
    }
  }

  .PasswordVisible {
    width: 20px;
    height: 20px;

    bottom: 12px;
    right: 12px;
    position: absolute;
    cursor: pointer;

    &.InvalidIcon {
      svg path {
        stroke: var(--red);
      }
    }
  }
}

.ErrorMessage {
  flex: 1;
  margin-bottom: 4px;

  @media screen and (max-width: 932px) {
    margin-left: 12px;
  }

  p {
    color: red;
    text-align: right;
    font-size: 12px;
  }
}

.LoaderContainer {
  padding: 4px;
  text-align: center;
  color: var(--dusty-gray);
}
