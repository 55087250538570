.FileUploader {
  width: 100%;
  margin-bottom: 25px;
  text-align: center;
  position: relative;
}

.DndBlock {
  position: relative;
  align-items: center;
  background-image: none !important;
  border-radius: 16px;
  width: 100%;
  height: 148px;
  padding: 0;

  :global(.rs-uploader-trigger-btn) {
    height: 148px;
  }
}

.DndError {
  div:nth-child(2) {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='24' ry='24' stroke='red' stroke-width='4' stroke-dasharray='16' stroke-dashoffset='12' stroke-linecap='butt'/%3e%3c/svg%3e") !important;
  }
}

.ErrorText {
  position: absolute;
  top: 10px;
  color: red;
}

.DragText {
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: var(--matte-black);
  margin-bottom: 8px;
}

.AssessmentAssign {
  padding-top: 22px;
}

.DragImage {
  margin-bottom: 16px;
}

.DragSelectText {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: var(--dark-grey);

  .ActiveText {
    margin-right: 4px;
    color: var(--refined-teal);
  }
}
