.Main {
  display: flex;
  flex-grow: 1;
  justify-content: center;

  @media screen and (max-width: 1180px) {
    padding: 0 16px;
  }

  @media screen and (max-width: 932px) {
    padding: 0;
  }
}

.Container {
  display: flex;
  justify-content: center;
  max-width: 1170px;
  min-width: 0;
  padding-bottom: 32px;
  position: relative;
  width: 100%;

  > * {
    min-width: 0;
  }

  @media screen and (max-width: 932px) {
    padding-bottom: 24px;
  }
}
