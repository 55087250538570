.MobileDropDown {
  height: 34px;
  margin-left: 12px;
  padding-left: 12px;
  border-left: 1px solid var(--cool-grey);
  justify-content: center;
  align-items: center;
  display: none;
  cursor: pointer;

  @media screen and (max-width: 1180px) {
    display: flex;
  }

  &.Active {
    svg {
      transform: rotate(0deg);
    }
  }

  svg {
    transform: rotate(180deg);
    transition: transform 0.2s ease;

    path {
      fill: var(--dark-grey);
    }
  }
}
