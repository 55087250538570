.Tags {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-bottom: 0;
  align-content: flex-start;
  gap: 8px;

  &.Column {
    flex-direction: column;
  }

  li {
    padding: 4px 16px;
    background: var(--light-teal);
    border-radius: 1000px;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    color: var(--refined-teal);
    max-width: 100%;
    display: flex;
    align-items: center;

    span {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: inline-block;
    }

    &.Organization {
      color: var(--refined-teal);
      background: var(--light-teal);
    }

    &.Group {
      color: var(--orange);
      background: var(--champagne);
    }

    &.SubGroup {
      color: var(--citrus);
      background: var(--china-ivory);
    }

    &.Name {
      color: var(--dark-grey);
      background: var(--white);
      border: 1px solid var(--cool-grey);
    }

    &.More {
      cursor: pointer;
      color: var(--refined-teal);
      background: var(--white);
      border: 1px solid var(--light-teal);
    }

    button {
      background: none;
      margin-left: 12px;

      svg {
        width: 8px;
        height: 8px;

        path {
          fill: currentColor;
        }
      }
    }
  }
}
