.ErrorContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 32px;
  padding: 12px 16px 16px;
  border-radius: 8px;
  border: 1px solid var(--red);
  background-color: #ffedef;

  .Title {
    font-size: 12px;
    line-height: 13.8px;
    font-weight: 400;
    color: var(--matte-black);
  }

  .ErrorFields {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    .ErrorField {
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: -0.5px;
      text-decoration: underline;
      text-transform: capitalize;
      color: var(--red);
    }
  }
}
