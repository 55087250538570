$animation-duration: 0.75s;
$padding-lg-init: 9px 0px;
$padding-lg: 9px 16px;
$padding-sm-init: 2.5px 0px;
$padding-sm: 2.5px 16px;

.Container {
  flex: 1;
  display: flex;

  &.Sm {
    .BarContainer {
      .Bar {
        height: 16px;
        padding: $padding-sm-init;

        span {
          font-weight: 600;
          font-size: 10px;
          line-height: 11px;
        }
      }
    }
  }

  &.Low {
    .BarContainer:nth-child(1) {
      .Bar {
        background-color: var(--chartreuse);
      }
    }
  }

  &.Medium {
    .BarContainer:nth-child(1),
    .BarContainer:nth-child(2) {
      .Bar {
        background-color: var(--mantis);
      }
    }
  }

  &.High {
    .BarContainer {
      .Bar {
        background-color: var(--refined-teal);
      }
    }
  }

  .BarContainer:nth-child(1) {
    .Bar {
      animation-delay: $animation-duration;
    }
  }

  .BarContainer:nth-child(2) {
    .Bar {
      animation-delay: $animation-duration * 2;
    }
  }

  .BarContainer:nth-child(3) {
    .Bar {
      animation-delay: $animation-duration * 3;
    }
  }

  .BarContainer {
    min-width: 56px;
    position: relative;
    width: 100%;

    .BarValue {
      margin-top: 4px;
      display: flex;

      @media screen and (max-width: 932px) {
        justify-content: center;
      }

      span {
        display: flex;
        align-items: center;
        color: var(--matte-black);
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
      }

      span:not(:last-child)::after {
        content: '';
        display: inline-block;
        margin: 0 4px;
        height: 10px;
        width: 1px;
        background-color: var(--dark-grey);
      }
    }

    &:not(:last-child) {
      margin-right: 4px;
    }

    &:nth-child(1) {
      .BarWrapper {
        border-top-left-radius: 1000px;
        border-bottom-left-radius: 1000px;

        .Bar {
          color: var(--matte-black);
        }
      }
    }

    &:nth-child(3) {
      .BarWrapper {
        border-top-right-radius: 1000px;
        border-bottom-right-radius: 1000px;
      }
    }

    .BarWrapper {
      width: 100%;
      overflow: hidden;
      box-shadow: var(--accelerants-shadow);
    }

    .Bar {
      height: 32px;
      width: 0%;
      overflow: hidden;
      padding: $padding-lg-init;
      background-color: var(--white);
      color: var(--white);
      display: flex;
      align-items: center;
      animation-fill-mode: forwards;
      animation-duration: $animation-duration;

      &.Zero {
        background-color: var(--white) !important;
      }

      span {
        display: none;
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        text-transform: uppercase;

        &.Visible {
          display: block;
        }
      }
    }
  }

  &.AnimationLg {
    &.Lg {
      .BarContainer {
        .Bar {
          animation-name: fill-lg;
        }
      }
    }
  }

  &.AnimationSm {
    &.Sm {
      .BarContainer {
        .Bar {
          animation-name: fill-sm;
        }
      }
    }
  }
}

@keyframes fill-lg {
  0% {
    padding: $padding-lg-init;
    width: 0%;
  }

  75% {
    padding: $padding-lg;
  }

  100% {
    padding: $padding-lg;
    width: 100%;
  }
}

@keyframes fill-sm {
  0% {
    padding: $padding-sm-init;
    width: 0%;
  }

  75% {
    padding: $padding-sm;
  }

  100% {
    padding: $padding-sm;
    width: 100%;
  }
}
