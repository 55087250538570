.Container {
  width: 550px;

  @media screen and (max-width: 932px) {
    padding: 0 16px;
    width: 100%;
  }

  .ForgotPassword {
    .ForgotPasswordControls {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-top: 32px;

      @media screen and (max-width: 932px) {
        flex-direction: column;
        gap: 12px;
      }

      button {
        width: 212px;

        @media screen and (max-width: 932px) {
          width: 100%;
          min-width: 100%;
        }

        &:nth-child(1) {
          @media screen and (max-width: 932px) {
            order: 2;
          }
        }
      }
    }
  }
}
