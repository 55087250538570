.SecondaryOrganizations {
  padding-top: 16px;
  border-top: 1px solid var(--lines);
  border-bottom: 1px solid var(--lines);

  .Label {
    color: var(--dark-grey);
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
  }

  .Tags {
    margin-right: 8px;
    margin-bottom: 8px;
    display: flex;

    @media screen and (max-width: 932px) {
      flex-direction: column;
      gap: 8px;
      margin-right: 0;
      align-items: flex-start;
    }

    svg {
      flex-shrink: 0;
    }
  }

  .More {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 6px 16px;
    width: 88px;
    min-height: 32px;
    margin-left: 8px;
    border: 1px solid var(--light-teal);
    border-radius: 1000px;
    color: var(--refined-teal);
    text-align: center;
    white-space: nowrap;

    @media screen and (max-width: 932px) {
      margin-left: 0;
      padding: 4px 12px;
    }
  }

  .AddOrganizationButtonContainer {
    width: 100%;

    button {
      padding-left: 8px;
      justify-content: start;
    }
  }

  .ViewAllButtonContainer {
    @media screen and (max-width: 932px) {
      button {
        width: 100%;
      }
    }
  }
}
