.Icon {
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Title {
  margin-bottom: 12px;
  font-size: 24px;
  font-weight: 700;
  line-height: 27.6px;
  letter-spacing: -0.02em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.Description {
  margin-bottom: 40px;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
