.Avatar {
  margin-bottom: 42px;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 932px) {
    margin-bottom: 24px;
  }
}

.InfoBlock {
  margin-bottom: 32px;
  padding: 12px 16px 16px;
  background: var(--ivory-1);
  border: 1px solid var(--chartreuse);
  border-radius: 8px;

  display: flex;
  flex-wrap: wrap;

  flex-direction: column;

  @media screen and (max-width: 932px) {
    padding: 12px;
  }

  .Element:not(:last-child) {
    margin-bottom: 16px;
  }

  .Title {
    font-size: 12px;
    line-height: 14px;
  }

  .Info {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
  }
}

.DeleteAvatar {
  margin-top: 15px;
}

.CustomAvatar {
  width: 160px;
  height: 160px;
  position: relative;
  align-self: center;
  margin-bottom: 4px;

  @media screen and (max-width: 932px) {
    width: 120px;
    height: 120px;
  }

  &:has(.Input:focus-visible) {
    outline: -webkit-focus-ring-color auto 1px;
  }

  .Input {
    width: 0px;
    height: 0px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .AvatarLabel {
    cursor: pointer;
    overflow: hidden;
    width: 160px;
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 50%;
    background: rgba(24, 153, 162, 0.17);

    @media screen and (max-width: 932px) {
      width: 120px;
      height: 120px;
    }

    svg {
      width: 96px;
      height: 96px;

      @media screen and (max-width: 932px) {
        width: 76px;
        height: 76px;
      }
    }
  }

  .Image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .EditIcon {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 30px;
    height: 30px;

    border-radius: 50%;
    border: 2px solid var(--white);
    background-color: var(--refined-teal);

    position: absolute;
    right: -8px;
    bottom: 0px;
  }
}
