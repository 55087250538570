.Container {
  font-size: 14px !important;
  color: var(--white);
  background-color: var(--refined-teal) !important;

  @media screen and (max-width: 932px) {
    width: 34px !important;
    height: 34px !important;
  }
}
