.TooltipContainer {
  overflow: hidden;
  position: absolute;
  border-radius: 8px;
  box-shadow: var(--tooltip-shadow);
  transform: translate(0, -110%);
}

.OutletContainer {
  position: absolute;
  top: 27px;
  left: 0;
}

.PointTooltip {
  z-index: 1060;
  position: relative;
  width: 260px;
  padding: 12px 16px;
  border-radius: 8px;
  background-color: var(--white);
  box-shadow: var(--block-shadow);

  @media screen and (max-width: 932px) {
    width: 192px;
  }

  &.DashboardGroup {
    width: 172px;
    color: var(--matte-black);
  }
}
