.customNoRowsOverlay {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 46px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: var(--dark-grey);
}
