.Container {
  min-width: 0;

  @media screen and (max-width: 932px) {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .CardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    gap: 8px;

    .CardHeaderTitleContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 0;

      @media screen and (max-width: 932px) {
        flex-direction: column;
        align-items: stretch;
        gap: 8px;
      }

      h3 {
        margin-right: 8px;
        flex-shrink: 0;

        @media screen and (max-width: 932px) {
          margin-right: 0;
        }
      }
    }
  }

  .CardBody {
    flex: 1;
  }

  .MobileLabelContainer {
    margin-bottom: 12px;
  }

  .CardContent {
    padding: 16px 0;
    border-radius: 8px;
    background-color: var(--white);
    box-shadow: var(--block-shadow);

    @media screen and (max-width: 932px) {
      height: 100%;
    }
  }
}
