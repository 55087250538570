.FormItem {
  margin-top: 24px;
  padding-left: 0;
  padding-right: 0;

  &.Small {
    &:nth-child(3n) {
      padding-left: 18px;
    }
  }

  &:nth-child(even) {
    padding-left: 18px;
  }

  .LargeLabel {
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 8px;
  }
}
