.DeletionModal {
  p {
    text-align: center;

    span {
      color: var(--red);
      font-weight: 500;
    }

    font-family: 'Inter', serif;
    font-size: 12px;
    line-height: 15px;
    color: var(--dark-grey);
  }
}
