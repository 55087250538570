.Container {
  width: 100%;
}

.Chart {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
}

.CardInfo {
  margin-bottom: 12px;
}

.SubCharts {
  margin-top: 32px;
}

.SubChart {
  position: relative;
  display: flex;
  justify-content: center;
}

.DoughnutContainer {
  position: relative;

  .Tooltip {
    position: absolute;
    top: 0;
    right: -24px;
  }
}

.Title {
  margin-top: 8px;
  text-align: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: var(--matte-black);
}
