.Dashboard {
  width: inherit;

  @media screen and (max-width: 932px) {
    padding: 0 16px;
  }

  .PageTitle {
    font-weight: 600;
    font-size: 28px;
    line-height: 43px;
    letter-spacing: -1px;
    margin-bottom: 24px;
  }

  .DashboardContent {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 162px 162px;
    grid-auto-rows: 162px 162px;
    column-gap: 16px;
    row-gap: 16px;

    @media screen and (max-width: 932px) {
      grid-template-columns: 1fr;
      grid-template-rows: 96px;
      grid-auto-rows: 96px;
    }

    .Element {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 16px;

      @media screen and (max-width: 932px) {
        flex-direction: row;
        justify-content: flex-start;
        padding: 24px;
      }

      cursor: pointer;
      background: var(--white);
      box-shadow: var(--block-shadow);
      border-radius: 8px;
      transition: 0.4s;
      text-decoration: none;

      .Icon {
        width: 54px;
        height: 54px;
        flex-shrink: 0;

        @media screen and (max-width: 932px) {
          margin-right: 16px;
        }
      }

      .Title {
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
        text-transform: uppercase;
        background: linear-gradient(282.78deg, var(--refined-teal) 0%, var(--chartreuse) 109.53%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }

      &:hover {
        filter: drop-shadow(0px 11px 34px rgba(101, 184, 117, 0.4));
      }
    }
  }
}
