.Container {
  &:global(.MuiInputBase-root) {
    background-color: var(--white);
    width: 200px;
    height: 32px;
    border-radius: 20px;
    font-size: 12px;

    :global(.MuiInputBase-input:placeholder-shown) {
      text-overflow: ellipsis;
    }

    @media screen and (max-width: 932px) {
      flex: 1;
    }
  }

  .SearchIcon {
    color: var(--dark-grey);
  }
}
