.Container {
  width: 495px;

  @media screen and (max-width: 932px) {
    padding: 0 16px;
    width: 100%;
  }

  .TitleContainer {
    @media screen and (max-width: 932px) {
      margin-bottom: 32px;
    }
  }

  .OnboardingProfile {
    .OnboardingForm {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .OnboardingControls {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 15px;

      button {
        width: 212px;
      }
    }
  }

  .AvatarWrapper {
    width: 90px;
    height: 90px;
    position: relative;
    align-self: center;
    margin-bottom: 4px;

    .AvatarLabel {
      cursor: pointer;
      overflow: hidden;

      width: 90px;
      height: 90px;

      display: flex;
      align-items: center;
      justify-content: center;

      border-radius: 50%;
      background: rgba(24, 153, 162, 0.17);
    }

    .Image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .EditIcon {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 30px;
      height: 30px;

      border-radius: 50%;
      border: 2px solid var(--white);
      background-color: var(--refined-teal);

      position: absolute;
      right: -8px;
      bottom: 0;
    }
  }

  .Buttons {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .Save {
      width: 212px;

      @media screen and (max-width: 932px) {
        width: 100%;
      }
    }

    .ChangePassBtn {
      padding-top: 32px;

      @media screen and (max-width: 932px) {
        width: 100%;
        padding-top: 12px;
      }
    }
  }

  .EmailContainer {
    display: flex;

    .InputContainer {
      flex: 1;
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      background: none;
      height: 43px;
      margin-top: 22px;
      padding-left: 10px;

      svg {
        height: 40px;
        width: 26px;
      }
    }
  }
}
