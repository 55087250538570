:global(.rs-modal-content) {
  padding: 24px 30px;
  border: 1px solid var(--cool-grey);
  box-shadow: var(--modal-shadow);
  border-radius: 10px;

  @media screen and (max-width: 700px) {
    padding: 32px 16px;
  }
}

.CloseIcon {
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 12px;
  background: none;
}

.Modal {
  width: min-content !important;

  &.Large {
    width: 1058px !important;

    :global(.rs-modal-content) {
      width: 100% !important;
    }
  }

  :global(.rs-modal-body) {
    padding-bottom: 0;
  }

  &.Overflow {
    .ModalDialog {
      :global(.rs-modal-content) {
        max-height: 453px;
      }
    }
    .ModalHeader {
      margin-bottom: 4px;
    }
  }

  .ModalDialog {
    :global(.rs-modal-content) {
      width: 495px;
      background: var(--white);
      box-shadow: none;
      overflow: hidden;
      display: flex;
      align-items: center;
      flex-direction: column;

      @media screen and (max-width: 700px) {
        min-width: calc(100dvw - 32px);
        width: auto;
      }

      @media screen and (max-width: 1120px) {
        max-height: calc(100dvh - 16px);
      }
    }
  }

  .ModalHeader {
    display: flex;
    align-items: center;
    flex-direction: column;

    .AttentionModalHeader {
      display: flex;
      flex-direction: column;
      align-items: center;

      h3 {
        margin-top: 8px;
        font-weight: 700;
        font-size: 28px;
        line-height: 32px;
        letter-spacing: -2px;
        color: var(--red);
      }
    }

    &.NoSpacing {
      margin-bottom: 0px;
    }

    .ModalTitle {
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      line-height: 32.2px;
      letter-spacing: -2px;
      color: var(--refined-teal);
      margin-bottom: 4px;
      text-align: center;

      @media screen and (max-width: 700px) {
        margin-bottom: 8px;
      }
    }

    .ModalSubTitle {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 130%;
      color: var(--dark-grey);
      text-align: center;
      white-space: pre-line;
    }
  }

  .ModalBody {
    width: 100%;
    margin-top: 32px;

    > *:last-child {
      margin-bottom: 0;
    }

    &.NoSpacing {
      margin-top: 0px;
    }

    &.IsForm {
      margin-bottom: 0;
    }
  }

  .ModalFooter {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 32px;

    .ModalGeneralControls {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;

      button:only-child {
        margin: 0 auto;
      }

      @media screen and (max-width: 576px) {
        flex-direction: column;
        gap: 12px;

        button:first-child {
          order: 2;
        }
      }
    }

    .FooterDeleteActionButton {
      display: flex;
      justify-content: center;
      margin-top: 12px;
    }
  }
}
