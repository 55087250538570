.Step {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;

  .TextBox {
    display: flex;
    gap: 4px;
    align-items: center;
    background-color: var(--white);
    border-radius: 4px;
    padding: 6px 16px;
  }

  .Title {
    font-weight: 700;
    line-height: 20.7px;
    letter-spacing: -1px;
    font-size: 18px;
    color: var(--menu-text);
  }

  .Point {
    border-radius: 50%;
    height: 10px;
    width: 10px;
    background: var(--light-teal);
    position: relative;
    z-index: 2;
    margin-top: 16px;
  }

  &.StepCompleted {
    .Title {
      color: var(--accept);
    }

    .Point {
      background: var(--accept);
    }
  }

  &.PathCompleted {
    &:not(:last-child):after {
      width: 345px;
      background: var(--accept);
    }
  }

  &.Active {
    .TextBox {
      background-color: var(--light-teal);
    }

    .Title {
      color: var(--refined-teal);
    }

    .Point {
      border: 4px solid var(--light-teal);
      background: var(--refined-teal);
      height: 24px;
      width: 24px;
    }

    &:not(:last-child):after {
      bottom: 8px;
    }
  }

  &:not(:last-child):after {
    content: '';
    display: block;
    position: absolute;
    left: calc(50% - 4px);
    transform: translateY(-50%);
    width: 350px;
    height: 4px;
    background: var(--lines);
    bottom: 1px;
    z-index: 1;
  }
}
