.SectionWrapper {
  display: flex;
  width: 100%;
  background: var(--white);
  padding: 20px 16px;
  box-shadow: var(--block-shadow);
  border-radius: 8px;

  .ComparisonContainer {
    display: flex;
    flex: 1;

    > .AccelerantContainer {
      flex: 1;
    }

    > .AccelerantContainer:not(:last-child) {
      margin-right: 16px;
    }

    .TitleContainer {
      display: flex;
      margin-bottom: 12px;

      .Title {
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: var(--matte-black);
        margin-right: 8px;
      }
    }

    .AdditionalAccelerants {
      margin-top: 25px;

      > div:not(:last-child) {
        margin-bottom: 14px;
      }
    }
  }

  .ButtonContainer {
    min-width: 100px;
    margin-left: 16px;
    margin-top: 28px;
  }
}
