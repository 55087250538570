.GroupHierarchy {
  li {
    list-style-type: none;
    position: relative;
  }

  ul {
    margin-left: 20px;
  }

  .ColumnHeaders {
    padding-left: 13px;
    margin-bottom: 9px;
    display: flex;
    border-left: 8px solid transparent;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: var(--dark-grey);

    .Name {
      width: 340px;
    }

    .Description {
      width: 450px;
    }

    .Path {
      margin-right: 35px;
    }

    .Caret {
      margin-right: 10px;
      transition: 0.2s;
      flex-shrink: 0;
      transform: rotate(180deg);

      path {
        fill: var(--matte-black);
      }

      &.Active {
        transform: rotate(0deg);
      }
    }
  }
}
