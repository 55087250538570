.btnActive {
  opacity: 1;
}

.Disabled {
  pointer-events: none;
}

.menu {
  & > * {
    display: inline-block;
  }
  & > * + * {
    margin-left: 14px;

    @media screen and (max-width: 932px) {
      margin-left: 7px;
    }
  }
}

.toolbar {
  position: relative;
  margin-top: 10px;
  display: flex;
  align-items: center;
  height: auto;
  flex-wrap: wrap;
}

.button {
  cursor: pointer;
  background: none;

  svg {
    path {
      fill: var(--silver);
      stroke: var(--silver);
    }
    rect {
      fill: var(--silver);
    }
  }

  &.active {
    svg {
      path {
        fill: var(--black);
        stroke: var(--black);
      }
      rect {
        fill: var(--black);
      }
    }
  }
}
