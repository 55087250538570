.Container {
  display: flex;
  text-align: center;

  > div {
    flex: 1;
  }

  > div:not(:last-child) {
    border-right: 1px solid var(--cool-grey);
  }
}
