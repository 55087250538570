.InfoContainer {
  font-weight: 600;
  font-size: 14px;
  width: fit-content;
  display: block;
  padding: 6px 10px;
  border-radius: 8px;
  color: var(--matte-black);
  max-width: 100%;
  min-width: 0;

  &.Yellow {
    background-color: var(--ivory-2);
    border: 1px solid var(--tusk);
  }

  &.Blue {
    background-color: var(--twilight-blue);
    border: 1px solid var(--lines);
  }

  &.White {
    background-color: var(--white);
    border: 1px solid var(--lines);
  }
}
