@import '../../../../styles/mixins.scss';

.Context {
  width: inherit;

  .ContextTitle {
    display: flex;
    align-items: center;

    .ContextCounter {
      width: 22px;
      height: 22px;
      margin-left: 8px;
      border-radius: 50%;
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      letter-spacing: normal;
      font-size: 12px;
      color: var(--white);
      background-color: var(--refined-teal);
      line-height: normal;

      font-weight: 600;
    }
  }

  .ContextContentWrapper {
    display: flex;
    flex-direction: column;

    padding: 20px 16px;
    border-radius: 8px;
    box-shadow: var(--block-shadow);
  }
}

.ContextItemWrapper {
  display: flex;
  flex-direction: column;
  &:not(:last-child) {
    border-bottom: 1px solid var(--cool-grey);
    padding-bottom: 24px;
    margin-bottom: 24px;
  }

  .Title {
    color: var(--dark-grey);
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 4px;
  }

  .Question {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 16px;
    color: var(--matte-black);
    letter-spacing: -1px;
    @include breakText();
  }

  .Answer {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    @include breakText();

    &.Short {
      > div {
        > :first-child {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        > :not(:first-child) {
          display: none;
        }
      }
    }
  }

  .ItemButton {
    width: 136px;
    margin-top: 24px;

    @media screen and (max-width: 932px) {
      width: 100%;
    }
  }
}
