.Container {
  width: 100%;
  display: flex;
  justify-content: center;
  transition: width ease 0.3s;

  &.Sm {
    width: 64px;
  }

  &.Md {
    width: 136px;
  }

  &.Lg {
    width: 220px;
  }
}

.Ring {
  stroke: #e6ebf0;
  stroke-width: 2.5px;
  fill: transparent;
}

.Segment {
  fill: transparent;
  stroke-width: 2.5px;

  &.Animate {
    animation: drawSegment 1s ease forwards;
  }
}

.Value {
  font-size: 0.5em;
  line-height: 1;
  font-weight: bold;
  fill: var(--matte-black);
  opacity: 0;
  text-anchor: middle;
  animation: showValue 1s ease forwards;
}

@keyframes drawSegment {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes showValue {
  to {
    opacity: 1;
  }
}
