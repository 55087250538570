:root {
  // Design
  --white: #ffffff;
  --bg: #fbffff;
  --cool-grey: #e6ebf0;
  --dark-grey: #535353;
  --matte-black: #282828;
  --chartreuse: #cbe139;
  --red: #dc001c;
  --orange: #e97200;
  --light-teal: #d5eff1;
  --lines: #d3eff1;
  --refined-teal: #1899a2;
  --menu-text: #89b8be;
  --accept: #32bea6;

  // Custom
  --mantis: #73be6c;
  --twilight-blue: #f6feff;
  --twilight-blue-2: #ecfdff;
  --tusk: #eaf2b6;
  --black-squeeze: #f4fbfb;
  --dusty-gray: #999999;
  --silver: #cccccc;
  --black: #000000;
  --alto: #d3d3d3;
  --champagne: #fae8d8;
  --china-ivory: #fbffe4;
  --citrus: #aac10c;
  --coconut-cream: #f8fbe8;
  --iceberg: #d8f1f3;
  --jagged-ice: #c9ebee;
  --bermuda: #7bcfd5;
  --aqua-spring: #e8f7f8;
  --powder-blue-1: #b9e6e9;
  --powder-blue-2: #aae0e4;
  --morning-glory-1: #9adadf;
  --morning-glory-2: #8bd5da;
  --ivory-1: #fdffef;
  --ivory-2: #fdfff0;
  --corn-field: #f7ffc4;
  --young-plum: #b4c926;
  --venetian-red: #e04f5f;
  --green-glacier: #eaf0c0;
  --azure: #e8f8f9;
  --gold-metallic: #fbffdd;
  --status-sent: #7e7e7e;
  --status-opened: #006fff;
  --status-completed: #73be6c;
  --matterhorn: #535353;
  --oyster-bay: #d1ebec;

  // Properties
  --block-shadow: 0px 8px 43px rgba(40, 165, 173, 0.1);
  --tooltip-shadow: 0px 0px 36px rgba(40, 40, 40, 0.1);
  --menu-gradient: linear-gradient(90deg, #1899a2 0%, #ffffff 100%);
  --box-shadow: 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0287542), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0238443),
    0px 12.5216px 10.0172px rgba(0, 0, 0, 0.02), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0161557),
    0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0112458);
  --modal-shadow: 0px 8px 25px rgba(79, 122, 165, 0.06);
  --table-shadow: 0 8px 26px rgba(24, 153, 162, 0.16);
  --button-shadow: 2px 1000px 1px #ffffff inset;
  --header-shadow-1: 0px 8px 25px rgba(79, 122, 165, 0.08);
  --header-shadow-2: 0px 4px 29px rgba(101, 184, 117, 0.16);
  --accelerants-shadow: 0px 4px 43px rgba(40, 165, 173, 0.14);
  --image-overlay: rgba(0, 0, 0, 0.5);
  --mobile-card-shadow: 0px 8px 43px 0px rgba(40, 165, 173, 0.1);
}
