.GroupManage {
  width: inherit;

  .TitleInput {
    width: 100%;
    min-width: 300px;
    margin-bottom: 0;
    font-weight: 300;
    line-height: 20px;
  }

  .GroupManage_Content {
    display: flex;
    min-height: 80%;

    .LeftSide {
      width: 100%;
      margin-right: 32px;
      max-width: 293px;
      min-width: 0;
    }

    .RightSide {
      width: 100%;
      position: relative;
      min-width: 0;
    }
  }
}

.DisabledTab {
  display: none;
}

.ButtonGroup {
  width: 210px;
  display: flex;
  padding-top: 20px;
}

.RoleAddHR {
  margin-bottom: 17px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  .Line {
    width: 100%;
    height: 1px;
    background-color: var(--cool-grey);
  }

  .Title {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 25px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: var(--matte-black);

    svg {
      margin-right: 10px;
    }
  }
}

.PermissionsWrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 16px;
}

.PermissionsBlock {
  margin-top: 8px;
  margin-bottom: 24px;
}

.Checkboxes {
  padding-top: 24px;
  border-top: 1px solid var(--cool-grey);
  width: 100%;
  display: flex;
  justify-content: space-between;

  .Col {
    width: 410px;
    display: flex;
    flex-direction: column;
  }
}

.AddButton {
  margin-left: 10px;
  padding: 0 18px;

  svg {
    margin-right: 10px;
  }
}

.EmptyState {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .Text {
    margin-bottom: 20px;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: var(--dark-grey);
  }

  .Controls {
    button:first-child {
      margin-right: 10px;
    }
  }
}

.Controls {
  display: flex;
}

.TableHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .Controls {
    display: flex;
    justify-content: flex-end;
    width: 350px;

    button:last-child {
      margin-left: 5px;
    }
  }

  .BulkEdit {
    display: flex;
    align-items: center;

    .Select {
      width: 157px;
      margin-right: 16px;
    }
  }
}

.GroupName {
  width: 420px;
}
