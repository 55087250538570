$main-path-length: 1100;
$main-level-lengths: 0, 198, 336, 476, 634, 746, 892, $main-path-length;
$main-level-point-positions: [30, 275], [223, 272], [354, 259], [492, 209], [608, 103], [702, 54], [855, 34], [1020, 30];

$child-path-length: 450;
$child-level-lengths: 0, 55, 100, 170, 270, 340, 385, $child-path-length;
$child-level-point-positions: [20, 264], [68, 261], [105, 249], [150, 186], [178, 91], [205, 37], [254, 17], [295, 15];

$base-path-animation-duration: 600ms;
$points-animation-delay: $base-path-animation-duration + 200ms;
$assessment2-path-animation-delay: $points-animation-delay + 600ms;
$assessment1-path-animation-delay: $assessment2-path-animation-delay + 1000ms;
$level-points2-animation-delay: $assessment2-path-animation-delay + $base-path-animation-duration;
$level-points1-animation-delay: $assessment1-path-animation-delay + $base-path-animation-duration;

$point-radius: 5;
$level-point-radius: 12;
$level-point-stroke-width: 4;
$point-comparison-distance: 7.5;

.ChartWrapper {
  position: relative;
  border-width: 0 1px 1px;
  border-style: solid;
  border-color: var(--cool-grey);
}

.Chart {
  display: block;
}

.Path {
  fill: none;
  stroke: var(--cool-grey);
  animation: drawLine $base-path-animation-duration ease forwards;

  .Main & {
    stroke-width: 30;
    stroke-dasharray: $main-path-length;
    stroke-dashoffset: $main-path-length;
  }

  .Child & {
    stroke-width: 20;
    stroke-dasharray: $child-path-length;
    stroke-dashoffset: $child-path-length;
  }
}

.Assessment1Path {
  stroke: var(--refined-teal);
  animation-delay: $assessment1-path-animation-delay;
}

.Assessment2Path {
  stroke: var(--chartreuse);
  animation-delay: $assessment2-path-animation-delay;
}

@each $level-length in $main-level-lengths {
  $i: index($main-level-lengths, $level-length);

  .Level#{$i} {
    .Main & {
      stroke-dasharray: $level-length $main-path-length;
      stroke-dashoffset: $level-length;
    }
  }
}

@each $level-length in $child-level-lengths {
  $i: index($child-level-lengths, $level-length);

  .Level#{$i} {
    .Child & {
      stroke-dasharray: $level-length $child-path-length;
      stroke-dashoffset: $level-length;
    }
  }
}

.Point {
  transition: filter ease 0.3s;
  opacity: 0;
  cursor: pointer;
  animation: drawPoints 0.3s ease forwards;
  stroke: var(--lines);
  stroke-width: 1px;

  &.Yellow {
    stroke: var(--green-glacier);
  }

  &.Green {
    stroke: var(--lines);
  }

  &.Hidden {
    display: none;
  }

  &:hover {
    filter: drop-shadow(0px 0px 7px rgba(24, 153, 162, 0.54));
  }
}

.LevelPoint {
  fill: var(--refined-teal);
  animation-delay: $points-animation-delay;

  &.Yellow {
    fill: var(--chartreuse);
  }

  &.Green {
    fill: var(--refined-teal);
  }
}

.AssessmentValue1,
.AssessmentValue2 {
  stroke-width: 4px;
}

.AssessmentValue1 {
  fill: var(--refined-teal);
  stroke: var(--lines);
  animation-delay: $level-points1-animation-delay;
}

.AssessmentValue2 {
  fill: var(--chartreuse);
  stroke: #eaf0c0;
  animation-delay: $level-points2-animation-delay;
}

.Combined {
  stroke-width: 1px;
}

@each $level-point in $main-level-point-positions {
  $i: index($main-level-point-positions, $level-point);
  $level-point-x: nth($level-point, 1) - $level-point-radius + $level-point-stroke-width * 2;
  $level-point-y: nth($level-point, 2) - $level-point-radius + $level-point-stroke-width * 2;
  $combined-level-point-x: $level-point-x - $level-point-radius;
  $combined-level-point-y: $level-point-y - $level-point-radius;
  $point-x: nth($level-point, 1) - $point-radius;
  $point-y: nth($level-point, 2) - $point-radius;

  .Main {
    .ValuePoint#{$i} {
      &:not(.Combined) {
        transform: translate(#{$level-point-x}px, #{$level-point-y}px);
      }

      &.Combined {
        transform: translate(#{$combined-level-point-x}px, #{$combined-level-point-y}px);
      }
    }

    .LevelPoint#{$i} {
      transform: translate(#{$point-x}px, #{$point-y}px);
    }

    .LevelPointComparison#{$i} {
      transform: translate(#{$point-x + $point-comparison-distance}px, #{$point-y}px);
    }
  }
}

@each $level-point in $child-level-point-positions {
  $i: index($child-level-point-positions, $level-point);
  $level-point-x: nth($level-point, 1) - $level-point-radius + $level-point-stroke-width * 2;
  $level-point-y: nth($level-point, 2) - $level-point-radius + $level-point-stroke-width * 2;
  $combined-level-point-x: $level-point-x - $level-point-radius;
  $combined-level-point-y: $level-point-y - $level-point-radius;
  $point-x: nth($level-point, 1) - $point-radius;
  $point-y: nth($level-point, 2) - $point-radius;

  .Child {
    .ValuePoint#{$i} {
      &:not(.Combined) {
        transform: translate(#{$level-point-x}px, #{$level-point-y}px);
      }

      &.Combined {
        transform: translate(#{$combined-level-point-x}px, #{$combined-level-point-y}px);
      }
    }

    .LevelPoint#{$i} {
      transform: translate(#{$point-x}px, #{$point-y}px);
    }
  }
}

@keyframes drawLine {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes drawPoints {
  to {
    opacity: 1;
  }
}
