@import '../../../styles/mixins.scss';

.AssessmentDetailsPage {
  width: inherit;

  @media screen and (max-width: 932px) {
    padding: 0 16px;
  }
}

.AssessmentInfoContainer {
  margin-bottom: 32px;

  .AssessmentInfo {
    &:not(:last-of-type) {
      margin-bottom: 24px;
    }

    span {
      display: block;
      margin-bottom: 8px;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: var(--dark-grey);
    }

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: var(--matte-black);
    }
  }
}

.IconContainer {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  background: var(--light-teal);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;

  svg {
    width: 36px;
    height: 40px;

    path {
      fill: var(--refined-teal);
    }
  }
}

.TagsContainer {
  &:not(:last-child) {
    margin-bottom: 24px;
  }

  .Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;

    h3 {
      font-weight: 700;
      font-size: 18px;
      line-height: 27px;
      letter-spacing: -1px;
      color: var(--matte-black);
    }

    svg {
      width: 11.5px;
      height: 11.5px;
    }
  }
}

.PageTitle {
  display: flex;
  align-items: center;

  @media screen and (max-width: 932px) {
    width: 100%;
    justify-content: space-between;
  }

  button {
    flex-shrink: 0;
  }

  h3 {
    margin-right: 14px;
    font-weight: 700;
    font-size: 28px;
    line-height: 43px;
    letter-spacing: -1px;
    padding-right: 1px;
    max-width: 500px;
    @include ellipsis;
  }
}

.PageTitleActions {
  display: flex;
}
