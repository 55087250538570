.PageTitle {
  width: 100%;
}

.SettingsContainer {
  display: flex;
}

.MainContainer {
  width: calc(100% - 293px);
  margin-left: 32px;
}

.FileUploader {
  margin-bottom: 25px;
}

.SidebarContainer {
  width: 293px;
  max-width: 293px;
  min-width: 293px;
  box-sizing: border-box;

  .DndBlock {
    position: relative;
    align-items: center;
    background-image: none !important;
    border-radius: 16px;
    width: 293px;
    height: 148px;
    padding: 0;

    :global(.rs-uploader-trigger-btn) {
      height: 148px;
    }
  }

  .DndError {
    div:nth-child(2) {
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='24' ry='24' stroke='red' stroke-width='4' stroke-dasharray='16' stroke-dashoffset='12' stroke-linecap='butt'/%3e%3c/svg%3e") !important;
    }
  }
}

.Checkbox {
  &.Active {
    color: var(--refined-teal);
  }
}

.ErrorText {
  position: absolute;
  top: 10px;
  color: red;
}

.DragText {
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: var(--matte-black);
  margin-bottom: 8px;
}

.AssessmentAssign {
  padding-top: 22px;
}

.DragImage {
  margin-bottom: 16px;
}

.DragSelectText {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: var(--dark-grey);

  .ActiveText {
    margin-right: 4px;
    color: var(--refined-teal);
  }
}

.TabContainer {
  cursor: pointer;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  display: flex;
  align-items: center;
  letter-spacing: -1px;
  color: var(--matte-black);

  div {
    margin-right: 32px;
  }
}

.ActiveTab {
  transition: 0.3s ease-in-out all;
  color: var(--refined-teal);
}

.UnitRow {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16.5px 12px;
  gap: 10px;
  width: 100%;
  height: 62px;
  background: var(--white);
  border: 1px solid var(--cool-grey);
  border-radius: 6px;
  margin-bottom: 4px;

  .UnitName {
    margin-left: -10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: var(--dark-grey);
  }

  input {
    padding: 6px 24px 6px 12px;
    gap: 10px;
    width: 74px;
    height: 29px;
    background: var(--white);
    border: 1px solid var(--cool-grey);
    border-radius: 4px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: var(--dark-grey);
  }

  &.Selected {
    background: var(--light-teal);
    border: 1px solid var(--refined-teal);
    box-shadow: var(--block-shadow);

    .UnitName {
      color: var(--refined-teal);
    }
  }
}

.PreviewContainer {
  position: relative;
  display: block;
  justify-content: center;
  align-items: center;
  width: 293px;
  height: 148px;
  background: var(--white);
  border: 1px solid var(--cool-grey);
  border-radius: 16px;
  padding: 5px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
  }

  svg {
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
  }
}

.SectionListContainer {
  margin-top: 17px;
  position: relative;

  .UnitsLoader {
    top: 50px;
  }

  small {
    color: red;
    top: -15px;
    position: absolute;
  }
}

.Section {
  &:not(:last-child) {
    margin-bottom: 24px;
    border-bottom: 1px solid var(--cool-grey);
  }

  & > * {
    margin-bottom: 24px !important;
  }
}
