.AddFromCSV {
  width: inherit;

  @media screen and (max-width: 932px) {
    width: 100%;
    padding: 0 16px;
  }
}

.BulkAction {
  display: flex;
  align-items: center;

  .Selector {
    width: 157px;
    margin-right: 16px;

    @media screen and (max-width: 932px) {
      flex: 1;
    }
  }
}

.SelectAllButtonContainer {
  margin-bottom: 16px;
}

.MobileContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.PaginationContainer {
  margin-top: auto;
  margin-bottom: 64px;
  display: flex;
  justify-content: center;
}

.SelectedText {
  color: var(--matte-black);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;

  .Count {
    color: var(--refined-teal);
  }
}
