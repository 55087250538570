.Container {
  padding: 24px;
  background-color: var(--white);
  box-shadow: 0px 8px 25px 0px #4f7aa514;
  border: 1px solid var(--oyster-bay);
  border-radius: 8px;
  width: 371px;

  .Title {
    display: block;
    font-size: 16px;
    font-weight: 700;
    line-height: 18.4px;
    letter-spacing: -0.02em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: var(--matte-black);

    margin-bottom: 24px;
  }

  .Controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
