.PageContainer {
  width: 495px;

  @media screen and (max-width: 932px) {
    width: 100%;
    padding: 0 16px;
  }

  .UserForm {
    position: relative;

    & > div:not(:last-child) {
      margin-bottom: 20px;

      @media screen and (max-width: 932px) {
        margin-bottom: 8px;
      }
    }
  }

  .ClearAllButtonContainer {
    margin-top: 24px;
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .ControlsContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 12px;

    button {
      min-width: auto !important;
      flex: 1;
    }

    @media screen and (max-width: 932px) {
      flex-direction: column;

      button {
        flex: auto;
      }

      button:first-child {
        order: 2;
      }
    }
  }
}
