.Block {
  flex: 1;
  visibility: visible;
  opacity: 1;
  transition: opacity 0.3s ease;

  &.Hidden {
    visibility: hidden;
    opacity: 0;
  }

  .BlockHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    margin: 16px 0;

    h3 {
      font-weight: 700;
      font-size: 18px;
      line-height: 27px;
      letter-spacing: -1px;
      color: var(--matte-black);
    }
  }

  .BlockUsers {
    width: 100%;
    padding: 0 16px;

    span {
      display: block;
      padding: 12px 16.5px;
      background-color: var(--coconut-cream);
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: var(--dark-grey);
      border-radius: 6px;

      &:not(:last-child) {
        margin-bottom: 4px;
      }
    }
  }
}
