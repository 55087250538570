.Container {
  position: relative;
  background-color: var(--azure);
  border: 1px solid transparent;
  transition: border 0.3s ease;
  width: 35px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  flex-shrink: 0;
  border-radius: 6px;
  background: var(--white);
  box-shadow: var(--header-shadow-1);
  cursor: pointer;

  span {
    color: var(--refined-teal);
    pointer-events: none;
  }

  &.Active {
    border: 1px solid var(--refined-teal);
  }

  @media screen and (max-width: 1180px) {
    position: static;
    padding-top: 0px;
  }

  :global(.MiddleLevelsNestedContainer) {
    display: flex;
    align-items: center;
    position: absolute;
    top: 52px;
    left: 0;
    padding: 10px;
    border-radius: 6px;
    background-color: var(--white);
    box-shadow: var(--header-shadow-1);
    z-index: 1;
    gap: 8px;
    overflow-x: auto;
    max-width: 652px;

    @media screen and (max-width: 1180px) {
      padding: 4px;
      width: max-content;
      max-width: calc(100dvw - 24px);
      overflow-x: auto;
      top: 56px;
      left: 12px;
      background: var(--azure);

      svg {
        flex-shrink: 0;
      }
    }
  }
}
