.Container {
  width: 495px;

  @media screen and (max-width: 932px) {
    width: 100%;
    padding: 0 16px;
  }
}

.PermissionsBlock {
  margin-bottom: 32px;

  &.Checkboxes {
    display: flex;
    flex-direction: column;
  }
}

.RoleAddHR {
  margin-bottom: 17px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  .Line {
    width: 100%;
    height: 1px;
    background-color: var(--cool-grey);
  }

  .Title {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 25px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: var(--matte-black);

    svg {
      margin-right: 10px;
    }
  }
}

.Errors {
  margin-bottom: 24px;

  p {
    font-size: 14px;
    color: var(--red);
  }
}

.ButtonsContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 12px;
  margin-top: 24px;

  button {
    min-width: auto !important;
    flex: 1;
  }

  @media screen and (max-width: 932px) {
    flex-direction: column;

    button {
      flex: auto;
    }

    button:first-child {
      order: 2;
    }
  }
}

.CheckboxContainer {
  display: flex;
  flex-direction: column;
  margin-left: -8px;
}
