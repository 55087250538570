.ChartInfoContainer {
  display: flex;

  > div {
    flex: 1;
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-top: 1px solid var(--cool-grey);

    @media screen and (max-width: 932px) {
      padding: 12px 8px;
      justify-content: start;
    }
  }

  > div:not(:last-child) {
    border-right: 1px solid var(--cool-grey);
  }
}

.ChartInfoComparisonContainer {
  display: flex;

  > div {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: stretch;
    border-top: 1px solid var(--cool-grey);
    min-width: 0;
  }

  > div:not(:last-child) {
    border-right: 1px solid var(--cool-grey);
  }
}
