@import './mixins.scss';

// RSUITE MODAL
.rs-modal-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rs-modal {
  margin: 0;
}

.rs-modal-backdrop {
  background: rgba(40, 40, 40, 0.5);
  backdrop-filter: blur(2px);
}

// RSUITE RADIO
.rs-radio-checker {
  font-weight: 400;
  font-size: 16px;
  color: var(--matte-black);
}

.rs-radio-checked .rs-radio-wrapper:before {
  background-color: var(--refined-teal) !important;
}

.rs-radio-wrapper .rs-radio-inner:before,
.rs-radio-wrapper:before {
  background-color: var(--white) !important;
  border: 1.5px solid var(--refined-teal) !important;
}

.rs-radio-checked .rs-radio-inner:after {
  width: 8px;
  height: 8px;
  margin-top: 4px;
  margin-left: 4px;
  background-color: var(--refined-teal);
}

// RSUITE FILE UPLOADER
.rs-uploader-trigger-btn {
  cursor: pointer;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 178px;
  border: none !important;
  border-radius: 24px;
  transition: 0.3s;
  background-image: url("data:image/svg+xml;charset=utf8,%3csvg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='24' ry='24' stroke='%23A6B4C1FF' stroke-width='4' stroke-dasharray='16' stroke-dashoffset='12' stroke-linecap='butt'/%3e%3c/svg%3e") !important;

  &:hover {
    background-image: url("data:image/svg+xml;charset=utf8,%3csvg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='24' ry='24' stroke='%231899A2FF' stroke-width='4' stroke-dasharray='16' stroke-dashoffset='12' stroke-linecap='butt'/%3e%3c/svg%3e") !important;

    @media screen and (max-width: 932px) {
      background-image: url("data:image/svg+xml;charset=utf8,%3csvg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='24' ry='24' stroke='%23A6B4C1FF' stroke-width='4' stroke-dasharray='16' stroke-dashoffset='12' stroke-linecap='butt'/%3e%3c/svg%3e") !important;
    }
  }
}

.rs-uploader-file-items {
  .rs-uploader-file-item {
    display: flex;
    align-items: center;
    height: 40px;
    border-radius: 8px;
    border: 1px solid var(--cool-grey);
    padding: 0 20px 0 16px;

    .rs-uploader-file-item-icon-wrapper {
      display: none;
    }

    .rs-uploader-file-item-content {
      align-items: center;
    }

    .rs-uploader-file-item-panel {
      width: 100%;
      padding-right: 20px;
    }

    .rs-uploader-file-item-size {
      font-weight: 400;
      font-size: 10px;
      line-height: 11px;
      color: var(--dark-grey);
    }

    .rs-uploader-file-item-btn-remove {
      font-size: 8px;
      top: 15px;
      right: 22px;
    }
  }
}

// RSUITE TAG
.rs-tag-re {
  .rs-picker-toggle-placeholder {
    color: var(--dark-grey);
  }

  .rs-tag {
    cursor: pointer;
    display: flex;
    align-items: center;
    min-height: 32px;
    background: var(--light-teal);
    border-radius: 1000px;
    padding: 6px 16px;
    font-weight: 400;
    font-size: 14px;
    color: var(--refined-teal);

    @media screen and (max-width: 932px) {
      padding: 4px 12px;
    }

    .rs-tag-text {
      display: flex;
      align-items: center;
      justify-content: space-between;

      svg {
        margin-left: 16px;
      }
    }
  }
}

.rs-loader-backdrop-wrapper {
  z-index: 999;
}

// DROPDOWN
.rs-dropdown-toggle {
  text-align: start;
  background: var(--white);
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: var(--dark-grey);
  gap: 10px;
  width: 175px;
  border: 1px solid var(--cool-grey);
  border-radius: 8px;

  svg {
    color: var(--refined-teal);
  }
}

.rs-dropdown-menu {
  margin-top: 4px;
  width: 175px;
  border-radius: 8px;
  border: 1px solid var(--cool-grey);
}

.rs-dropdown-item {
  color: var(--dark-grey);
  padding: 12px 0 12px 12px;
}

.rs-dropdown-item:hover {
  background: var(--light-teal);
  color: var(--matte-black);
}

// Checkbox
.GlobalCheckbox {
  .rs-checkbox {
    .rs-checkbox-checker {
      width: 100%;
      min-height: 30px;
      display: flex;
      padding: 0;

      label {
        width: 100%;
        display: flex;
        align-items: center;
        margin-left: 40px;
        font-size: 14px;
        font-weight: 400;
        color: var(--dark-grey);
      }
    }

    .rs-checkbox-wrapper {
      top: 7px;

      .rs-checkbox-inner {
        &:before {
          border-radius: 5px;
          border-color: var(--dark-grey);
        }

        &:after {
          border-width: 0 1px 1px 0;
          width: 4px;
          height: 6px;
          margin-top: 4px;
          margin-left: 6px;
          opacity: 1;
        }
      }
    }

    &.rs-checkbox-checked {
      .rs-checkbox-wrapper {
        .rs-checkbox-inner {
          &:before {
            border-radius: 5px;
            border-color: var(--refined-teal) !important;
            background-color: var(--refined-teal) !important;
          }

          &:after {
            border-width: 0 1px 1px 0;
            width: 4px;
            height: 6px;
            margin-top: 4px;
            margin-left: 6px;
            opacity: 1;
            border-color: var(--light-teal) !important;
          }
        }
      }
    }
  }
}

// TOGGLE
.rs-toggle-presentation {
  min-width: 36px !important;
  background-color: var(--refined-teal) !important;
  height: 20px !important;
}
.rs-toggle-presentation::after {
  width: 12px !important;
  height: 12px !important;
  top: 4px !important;
  bottom: 4px !important;
}
.rs-toggle-checked .rs-toggle-presentation:after {
  margin-left: -16px !important;
}

// LOADER
.rs-loader-spin:after {
  border-color: var(--refined-teal) transparent transparent !important;
}

// POPOVER
.rs-popover {
  padding: 0;
  border-radius: 8px;
  background-color: var(--white);
  box-shadow: var(--block-shadow);
  filter: none;
}

.rs-breadcrumb {
  margin-bottom: 0;
}

.rs-picker-menu {
  z-index: 1001;
}

// UPLOAD
.rs-uploader-trigger-btn {
  padding: 16px;
}

// TAKE ASSESSMENT CHECKBOX
.rs-radio-group > .rs-radio {
  margin-left: 0;
}

.take-checkbox {
  .rs-checkbox-checker {
    width: 100%;
    min-height: 43px;
    display: flex;
    padding: 0;

    label {
      width: 100%;
      display: flex;
      align-items: center;
      margin-left: 40px;
      font-size: 14px;
      font-weight: 400;
      color: var(--dark-grey);
    }
  }

  .rs-checkbox-wrapper {
    top: 14px;

    .rs-checkbox-inner {
      &:before {
        border-radius: 5px;
        border-color: var(--dark-grey);
      }

      &:after {
        border-width: 0 1px 1px 0;
        width: 4px;
        height: 6px;
        margin-top: 4px;
        margin-left: 6px;
        opacity: 1;
        border-color: var(--light-teal) !important;
      }
    }
  }

  &.rs-checkbox-checked {
    background: var(--light-teal) !important;

    .rs-checkbox-wrapper {
      .rs-checkbox-inner {
        &:before {
          border-radius: 5px;
          border-color: var(--refined-teal) !important;
          background-color: var(--refined-teal) !important;
        }

        &:after {
          border-width: 0 1px 1px 0;
          width: 4px;
          height: 6px;
          margin-top: 4px;
          margin-left: 6px;
          opacity: 1;
          border-color: var(--light-teal) !important;
        }
      }
    }
  }
}

.rs-picker-menu.rs-picker-check-menu,
.rs-picker-menu.rs-picker-select-menu {
  padding-top: 0;
}

.rs-picker-select-menu-item {
  @include ellipsis;

  > div {
    @include ellipsis;
  }
}

// RSUITE DATE PICKER
.rs-picker-daterange-menu {
  --rs-calendar-cell-selected-hover-bg: var(--refined-teal);
  --rs-bg-active: var(--refined-teal);
  --rs-calendar-range-bg: var(--light-teal);
  --rs-input-focus-border: var(--refined-teal);
  --rs-listbox-option-hover-text: var(--refined-teal);
  --rs-listbox-option-hover-bg: var(--light-teal);
  --rs-calendar-cell-selected-hover-bg: color-mix(in srgb, var(--refined-teal), #000 15%);
  --rs-calendar-date-selected-text: var(--white);
  --rs-btn-link-text: var(--matte-black);
  --rs-btn-link-active-text: color-mix(in srgb, var(--refined-teal), #000 20%);
  --rs-btn-link-hover-text: var(--refined-teal);
  --rs-btn-primary-bg: var(--refined-teal);
  --rs-btn-primary-hover-bg: color-mix(in srgb, var(--refined-teal), #000 15%);
  --rs-btn-primary-focus-bg: color-mix(in srgb, var(--refined-teal), #000 15%);
  --rs-btn-primary-active-bg: color-mix(in srgb, var(--refined-teal), #000 20%);
  --rs-text-active: var(--refined-teal);

  .rs-picker-daterange-panel {
    .rs-picker-daterange-header {
      text-align: center;
    }
    .rs-calendar-header-month-toolbar {
      button {
        font-size: 14px;
      }
    }

    .rs-btn-link:hover {
      text-decoration: none;
    }
  }

  .rs-calendar-month-dropdown-row-wrapper {
    scrollbar-width: thin;
    scrollbar-color: var(--refined-teal) var(--lines);

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 1000px;
      background: var(--lines);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 100px;
      background-color: var(--refined-teal);
    }
  }

  .rs-calendar-table-cell-disabled .rs-calendar-table-cell-content {
    color: var(--dark-grey);
    text-decoration: none;
  }

  .rs-calendar-header-error {
    --rs-btn-subtle-text: #8e8e93;
    --rs-btn-subtle-hover-bg: #e5e5ea;
    --rs-btn-subtle-hover-text: #575757;
    --rs-btn-subtle-active-bg: var(--rs-btn-subtle-hover-bg);
    --rs-btn-subtle-active-text: #272c36;
    --rs-text-error: var(--rs-btn-subtle-text);
  }

  .rs-picker-daterange-calendar-group > div:first-child {
    .rs-calendar-header-month-toolbar:has(.rs-calendar-header-error) {
      .rs-calendar-header-backward {
        pointer-events: none;
        opacity: 0.8;
      }
    }
  }

  .rs-picker-daterange-calendar-group > div:last-child {
    .rs-calendar-header-month-toolbar:has(.rs-calendar-header-error) {
      .rs-calendar-header-forward {
        pointer-events: none;
        opacity: 0.8;
      }
    }
  }

  .rs-calendar-month-view .rs-calendar-header-title-date.rs-calendar-header-error:focus {
    color: var(--rs-btn-subtle-text) !important;
  }

  .rs-calendar-month-dropdown-cell:not(.rs-calendar-month-dropdown-cell-active).disabled
    .rs-calendar-month-dropdown-cell-content {
    text-decoration: none;
  }
}

.rs-picker-daterange {
  --rs-picker-value: var(--matte-black);
}

.custom-date-picker {
  display: block;
  --rs-picker-value: var(--matte-black);

  .rs-picker-toggle {
    border-radius: 8px;
    padding: 5px;
    border: 1px solid var(--oyster-bay);

    padding: 15px 12px !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
}

.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
  top: 13px;
}
