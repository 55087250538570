.TagList {
  display: flex;
  flex-direction: column;

  .Tags {
    width: fit-content;
    flex-shrink: 0;
    margin-bottom: 8px;

    svg {
      flex-shrink: 0;
    }
  }
}
