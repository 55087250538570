.Container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 16px;

  .Column {
    flex: 1;
  }
}

.Status {
  width: 6px;
  height: 6px;
  margin-right: 11px;
  border-radius: 50%;
}

.Disabled {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: var(--red);

  .Status {
    background: var(--red);
  }
}

.Active {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: var(--accept);

  .Status {
    background: var(--accept);
  }
}
