.Wrapper {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;

  .Container {
    width: 495px;
  }
}
