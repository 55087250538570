@import 'styles/mixins';

.Container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex: 1;
  gap: 24px;
  min-width: 0;
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid var(--cool-grey);
  background: var(--twilight-blue);

  &.Yellow {
    background: var(--ivory-2);
  }

  .Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    .Group,
    .People {
      font-weight: 600;
      color: var(--matte-black);
    }

    .Group {
      font-size: 18px;
      letter-spacing: -1px;
      padding-right: 1px;
      margin-right: 8px;
      @include ellipsis;
    }

    .People {
      min-width: fit-content;
      font-size: 14px;
      text-transform: uppercase;
    }
  }

  .Body {
    width: 100%;

    .ScrollableContainer,
    .EmptyGroup {
      display: flex;
      justify-content: center;
      align-self: stretch;
      width: 100%;
      height: 320px;
    }

    .EmptyGroup {
      align-items: center;
      gap: 10px;
      padding: 8px 12px;
      border-radius: 6px;
      border: 1px solid var(--cool-grey);
      background: var(--twilight-blue-2);

      &.Yellow {
        background: var(--gold-metallic);
      }
    }

    .User {
      display: flex;
      align-items: center;
      height: 50px;
      padding: 8px 12px;
      border: 1px solid var(--cool-grey);
      border-radius: 6px;
      background-color: var(--white);

      &:not(:last-child) {
        margin-bottom: 4px;
      }

      span {
        font-size: 14px;
        font-weight: 400;
        color: var(--matte-black);
        @include ellipsis();
      }
    }
  }
}
