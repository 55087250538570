.Wrapper {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.Container {
  width: 495px;

  @media screen and (max-width: 932px) {
    padding: 0 16px;
    width: 100%;
  }

  .PasswordSetup {
    .PasswordSetup_Form {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .SignUpValidation {
      padding: 12px 16px;
      background: var(--ivory-1);
      border: 1px solid var(--chartreuse);
      border-radius: 8px;
      margin-bottom: 17px;

      .ValidationTitle {
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 16px;
      }

      .ValidationBlock {
        .ValidationEl {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;

          svg {
            margin-right: 12px;
          }

          &:not(:last-child) {
            margin-bottom: 8px;
          }
        }
      }
    }
  }
}
