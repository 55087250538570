// TODO: Review and refactor
.AssessmentAside {
  overflow: auto;
  width: 293px;
  margin-right: 32px;
}

.AsideElement {
  cursor: pointer;
  height: 56px;
  padding: 0 18px;
  background: var(--white);
  box-shadow: var(--block-shadow);
  border-radius: 8px;
  margin-bottom: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: var(--menu-text);

  .Icon {
    margin-right: 12px;
  }

  &.Disabled {
    pointer-events: none;
    cursor: no-drop;
    opacity: 0.4;
  }

  &:hover {
    background: var(--menu-gradient);
    color: var(--white);

    // TODO: Implement via color property
    .Icon {
      svg {
        path {
          fill: var(--white);
        }

        circle {
          stroke: var(--white);
        }
      }
    }
  }
}

.DropdownSection {
  max-width: 293px;
  margin-top: 16px;
  padding-top: 24px;
  border-top: 1px solid var(--lines);

  .DropdownSection_Header {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: var(--refined-teal);
  }

  div:nth-child(1) {
    position: relative;
    display: flex;
    align-items: center;

    .ModuleBlock {
      cursor: pointer;

      svg {
        margin-right: 11px;
        transition: 0.3s ease-in-out all;
      }

      .ExpandSvg {
        transform: rotate(180deg);
      }

      p {
        font-weight: 600;
        font-size: 14px;
        text-transform: uppercase;
        color: var(--refined-teal);
      }
    }

    .AddButton {
      position: absolute;
      right: 0;
      cursor: pointer;
    }
  }

  .Modules {
    margin-top: 8px;
    max-height: 171px;
    overflow: auto;

    .LoaderContainer {
      min-height: 30px;
    }

    .ModuleItem {
      margin-bottom: 4px;
      position: relative;
      padding: 16px 18px;
      background: var(--white);
      box-shadow: var(--block-shadow);
      border-radius: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .Disabled {
        opacity: 0.5;
        pointer-events: none;
      }

      p {
        font-weight: 600;
        font-size: 14px;
        color: var(--matte-black);
      }

      .IconsBlock {
        display: flex;
        align-items: center;

        div {
          svg {
            cursor: pointer;
          }
        }

        div:not(:last-child) {
          display: flex;
          align-items: center;

          &::after {
            content: '';
            align-self: stretch;
            background-color: var(--lines);
            width: 1px;
            margin-left: 10px;
            margin-right: 10px;
          }
        }
      }
    }
  }
}

.CreateAssessmentWrapper {
  position: relative;
  min-height: 300px;

  .BuilderWrapper {
    display: flex;
    height: calc(
      100vh - 56px - 32px - 73px - 56px - 38px
    ); // Header height + Layout padding + TabBar height + LanguageSelector height + Footer height
    padding-bottom: 32px;
  }
}

.LanguageSelector {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
  gap: 16px;
  height: 32px;
  margin-bottom: 24px;
  background: var(--lines);

  button {
    font-weight: 600;
    font-size: 14px;
    color: var(--matte-black);
    cursor: pointer;
  }

  & > .SelectedLang {
    color: var(--refined-teal);
  }
}

.SettingsContainer {
  animation: roll-in-right 0.6s ease-out both;

  @keyframes roll-in-right {
    0% {
      transform: translateX(800px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
}

.Hidden {
  display: none;
}
