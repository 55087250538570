.ShadowBlock {
  background: var(--white);
  border-radius: 10px;
  width: 100%;
  border: 1px solid var(--lines);
  box-shadow: var(--block-shadow);
  height: fit-content;
  padding: 24px 30px;

  @media screen and (max-width: 932px) {
    padding: 24px 16px;
  }
}
