.Element {
  display: flex;
  align-items: center;

  font-weight: 400;
  font-size: 10px;
  line-height: 16px;

  z-index: 20;
  width: 550px;
  height: 34px;
  padding-left: 13px;
  margin-bottom: 4px;
  background: var(--white);

  border-radius: 8px;
  border: 1px solid var(--cool-grey);

  &.Lg {
    font-size: 14px;
    height: 48px;
  }

  .Avatar {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    margin-right: 10px;
  }

  .Path {
    margin-right: 23px;
  }

  .Name {
    width: 724px;
    font-size: 10px;
    color: var(--rs-text-primary);
    text-decoration: none;
  }

  .Description {
    width: 450px;
  }
}
