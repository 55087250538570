.Wrapper {
  display: inline-block;

  &.Empty {
    width: 0;
  }
}

.Content {
  padding: 12px 16px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--matte-black);
}
