.VisualisationHeader {
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 12px;
  background: var(--menu-text);
  color: var(--white);

  .Title {
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    text-transform: capitalize;
  }

  .EditButton {
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: transparent;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    color: var(--white);
    text-transform: uppercase;
    padding: 4px 8px;

    svg {
      path {
        fill: var(--white);
      }
    }
  }
}
