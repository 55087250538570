.UserState {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .UserInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-right: 12px;

    @media screen and (max-width: 1180px) {
      margin-right: 0;
    }

    a {
      text-decoration: none;
    }

    .Avatar {
      width: 34px;
      height: 34px;
      margin-right: 12px;
      cursor: pointer;

      @media screen and (max-width: 1180px) {
        margin-right: 0;
      }
    }

    .UserData {
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 1180px) {
        display: none;
      }

      .Name {
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: var(--matte-black);
        margin-bottom: 4px;
      }

      .Role {
        font-weight: 400;
        font-size: 10px;
        line-height: 11px;
        color: var(--dark-grey);
      }
    }
  }

  .SignOut {
    height: 34px;
    padding-left: 12px;
    border-left: 1px solid var(--cool-grey);

    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 1180px) {
      display: none;
    }

    .SignOutButton {
      cursor: pointer;
      height: 24px;
      width: 24px;
    }
  }
}
