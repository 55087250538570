.Footer {
  align-items: center;
  background-color: var(--matte-black);
  display: flex;
  height: 38px;
  justify-content: center;

  p,
  a {
    color: var(--white);
    font-size: 12px;
  }

  a:hover,
  a:visited {
    color: var(--white);
    text-decoration: underline;
  }
}
