.Header {
  margin-bottom: 32px;

  &:has(:global(#default-breadcrumb) > *) {
    margin-bottom: 4px;

    @media screen and (max-width: 1180px) {
      margin-bottom: 24px;
    }
  }

  @media screen and (max-width: 1180px) {
    margin-bottom: 24px;
  }
}

.Container {
  margin: auto;
  max-width: 1170px;
}

.FullWidthContainer {
  margin: auto;
  border-bottom: 1px solid var(--cool-grey);
}

.HeaderContainer {
  display: flex;
  align-items: center;
  padding: 6px 0;

  @media screen and (max-width: 1180px) {
    padding: 16px;
  }

  .HeaderLeft {
    display: flex;
    align-items: center;
    flex: 1;

    .HamburgerMenuContainer {
      @media screen and (max-width: 1180px) {
        margin-right: 16px;
      }
    }

    .Logo {
      cursor: pointer;
      margin-right: 36px;
      width: 102px;
      height: 36px;

      @media screen and (max-width: 1180px) {
        margin-right: 0;
      }
    }
  }

  .OrganizationSelector {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    margin-right: 36px;

    @media screen and (max-width: 1180px) {
      display: none;
    }
  }

  .HeaderControls {
    display: flex;
    align-items: center;

    .DashboardSwitchContainer {
      margin-right: 36px;

      @media screen and (max-width: 1180px) {
        display: none;
      }
    }
  }

  .HeaderBtnGroup {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
  }
}
