@import '../../../styles/mixins.scss';

.custom-single-select {
  width: 100%;

  &.error .rs-picker-toggle.rs-btn-default {
    border: 1px solid var(--red) !important;
  }

  .rs-picker-toggle.rs-btn-default {
    display: flex;
    align-items: center;
    justify-content: space-between;

    height: 44px;
    background: var(--white);
    border: 1px solid var(--cool-grey) !important;
    border-radius: 8px;
    box-shadow: none;

    & > div {
      max-width: 100%;
    }

    .rs-picker-toggle-caret.rs-icon {
      top: 10px;
    }

    .rs-picker-toggle-placeholder {
      max-width: 100%;
      color: var(--dark-grey);
      display: inline-block;
      vertical-align: middle;
      @include ellipsis;
    }

    .rs-picker-toggle-value {
      max-width: 100%;
      color: var(--matte-black);
      overflow: hidden;
      white-space: nowrap;
    }
  }

  &.sm {
    .rs-picker-toggle.rs-btn-default {
      height: 32px;
      background: var(--white);

      .rs-picker-toggle-caret.rs-icon {
        top: 5px;
      }
    }
  }
}

.custom-single-select-menu {
  &.rs-picker-menu {
    border: 1px solid var(--cool-grey);
    border-radius: 8px;
    margin-top: 4px;
    padding-top: 0px !important;
    box-shadow: none;
    max-width: 700px;
    z-index: 1001;
  }

  .rs-picker-select-menu {
    margin-bottom: 0px;

    .rs-picker-select-menu-item {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: var(--dark-grey);

      &:hover {
        background: var(--light-teal);
        color: var(--matte-black);
      }
    }

    .rs-picker-select-menu-item-active {
      background: var(--light-teal);
      color: var(--matte-black);
    }
  }
}

.custom-single-select-tree {
  width: 100%;

  &.error .rs-picker-toggle.rs-btn-default {
    border: 1px solid var(--red) !important;
  }

  .rs-picker-toggle.rs-btn-default {
    display: flex;
    align-items: center;
    justify-content: space-between;

    height: 44px;
    background: var(--white);
    border: 1px solid var(--cool-grey) !important;
    border-radius: 8px;
    box-shadow: none;

    & > div {
      max-width: 100%;
    }

    .rs-picker-toggle-caret.rs-icon {
      top: 10px;
    }

    .rs-picker-toggle-placeholder {
      max-width: 100%;
      color: var(--dark-grey);
      display: inline-block;
      vertical-align: middle;
      @include ellipsis;
    }

    .rs-picker-toggle-value {
      color: var(--matte-black);
      overflow: hidden;
      white-space: nowrap;
      max-width: 100%;
    }
  }

  &.sm {
    .rs-picker-toggle.rs-btn-default {
      height: 32px;
      background: var(--white);

      .rs-picker-toggle-caret.rs-icon {
        top: 5px;
      }
    }
  }
}

.custom-single-select-menu-tree {
  &.rs-picker-menu {
    border: 1px solid var(--cool-grey);
    border-radius: 8px;
    margin-top: 4px;
    padding-top: 0px !important;
    box-shadow: none;
    z-index: 1001;

    .rs-tree {
      padding: 5px 0;
    }
  }

  .rs-tree-node-active .rs-tree-node-label-content {
    border-radius: 8px;
    color: var(--matte-black);
    background: var(--light-teal);
  }

  .rs-tree-node-label {
    display: block;
    @include ellipsis;
  }

  .rs-tree-node-label-content {
    color: var(--dark-grey);

    &:hover {
      border-radius: 8px;
      color: var(--matte-black);
      background: var(--light-teal);
    }
  }

  .rs-picker-select-menu {
    margin-bottom: 0px;

    .rs-picker-select-menu-item {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: var(--dark-grey);

      &:hover {
        background: var(--light-teal);
        color: var(--matte-black);
      }
    }

    .rs-picker-select-menu-item-active {
      background: var(--light-teal);
      color: var(--matte-black);
    }
  }
}

.custom-select-visualisations {
  &.error {
    .rs-picker-toggle.rs-btn-default {
      .rs-picker-toggle-placeholder {
        color: var(--invalid-color);
      }
    }
  }

  .rs-picker-toggle.rs-btn-default {
    .rs-picker-toggle-value {
      color: var(--refined-teal);
    }

    .rs-picker-toggle-placeholder {
      font-size: 14px;
      font-weight: 400;
      color: var(--dark-grey);
    }

    .rs-picker-toggle-caret {
      color: var(--refined-teal);
    }
  }
}

.custom-select-menu-visualisations {
  .rs-picker-select-menu .rs-picker-select-menu-item {
    color: var(--refined-teal);
    font-size: 14px;
    font-weight: 400;
    @include ellipsis;

    &:hover {
      color: var(--refined-teal);
    }
  }
}

.LoaderContainer {
  padding: 4;
  color: var(--dusty-gray);
  text-align: center;
}
