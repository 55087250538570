.Container {
  display: flex;
  min-height: 32px;

  > *:not(:first-child) {
    margin-left: -12px;
  }

  > div {
    width: 32px;
    height: 32px;
    border: 1px solid var(--white);
  }

  &.Checked {
    > div {
      border: 1px solid var(--light-teal);
    }

    .More {
      background-color: var(--white);
    }
  }

  .More {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 0;
    background-color: var(--light-teal);
    border-radius: 50%;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
  }
}
