@import url('https://fonts.googleapis.com/css2?family=Arimo:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

@import './styles/variables.scss';
@import './styles/rsuite-rewrite.scss';
@import 'material-icons/iconfont/material-icons.css';
@import 'swiper/css';
@import 'swiper/css/navigation';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Arimo', serif !important;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100dvh;
  height: auto;
}

body {
  background: var(--bg);

  @media screen and (max-width: 932px) {
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
  }
}

button {
  background: none;
}

a {
  &:focus-visible {
    outline: -webkit-focus-ring-color auto 1px !important;
  }
}

/* Chrome, Safari, Edge, Opera */
input {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
}
