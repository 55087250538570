.Container {
  input::placeholder {
    color: var(--matterhorn) !important;
  }

  &:global(.MuiInputBase-root) {
    background-color: var(--white);
    width: 100%;
    // height: 40px;
    border-radius: 8px;
    font-size: 14px;

    :global(.MuiInputBase-input:placeholder-shown) {
      text-overflow: ellipsis;
    }

    @media screen and (max-width: 932px) {
      flex: 1;
    }
  }

  .SearchIcon {
    color: var(--dark-grey);
  }
}
