.Container {
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--lines);
  background: var(--white);
  border-radius: 8px;
  align-items: center;

  @media screen and (max-width: 932px) {
    min-height: calc(100dvh - 255px);
    padding-top: 0;
  }

  .ContentContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 24px 16px;
  }

  .ImageBox {
    height: 120px;
    margin-bottom: 16px;
    overflow: hidden;

    @media screen and (max-width: 932px) {
      height: auto;

      svg {
        width: 100%;
        height: 102.286px;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
