.Container {
  margin-bottom: 32px;

  @media screen and (max-width: 932px) {
    margin-bottom: 24px;
  }

  .AssessmentInfo {
    &:not(:last-of-type) {
      margin-bottom: 24px;
    }

    span {
      display: block;
      margin-bottom: 8px;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: var(--dark-grey);
    }

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: var(--matte-black);
    }
  }
}
