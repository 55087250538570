.Section {
  margin-bottom: 48px;

  @media screen and (max-width: 932px) {
    margin-bottom: 0;
  }
}

.SectionTitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: -1px;
  color: var(--matte-black);

  @media screen and (max-width: 932px) {
    line-height: normal;
    display: none;
  }
}

.SectionLabel {
  margin-top: 10.5px;
  display: flex;
  align-items: center;

  > span {
    margin-right: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: var(--matte-black);
    min-width: max-content;
  }
}

.SectionContent {
  margin-top: 12px;
}
