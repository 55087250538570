.Container {
  display: flex;
  align-items: center;
  height: 20px;

  .LeftLabel,
  .RightLabel {
    font-weight: 400;
    font-size: 14px;
    color: var(--dark-grey);
    background: none;
    cursor: pointer;
  }

  .LeftLabel {
    &.Active {
      color: var(--refined-teal);
    }
  }

  .RightLabel {
    &.Active {
      color: var(--young-plum);
    }
  }
}
