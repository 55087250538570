.GroupList {
  width: inherit;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 932px) {
    padding: 0 16px;
  }

  .AddButton {
    margin-left: 10px;
    padding: 0 18px;

    svg {
      margin-right: 10px;
    }
  }

  .MobileContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .PaginationContainer {
    margin-top: auto;
    margin-bottom: 64px;
    display: flex;
    justify-content: center;
  }
}
