.Container {
  &:global(.rs-checkbox-checker) {
    width: 100%;
    min-height: 30px;
    display: flex;
    padding: 0;

    label {
      width: 100%;
      display: flex;
      align-items: center;
      margin-left: 40px;
      font-size: 14px;
      font-weight: 400;
      color: var(--dark-grey);
    }
  }

  &:global(.rs-checkbox-wrapper) {
    top: 7px;

    :global(.rs-checkbox-inner) {
      &::before {
        border-radius: 5px;
        border-color: var(--dark-grey);
      }

      &::after {
        border-width: 0 1px 1px 0;
        width: 4px;
        height: 6px;
        margin-top: 4px;
        margin-left: 6px;
        opacity: 1;
      }
    }
  }

  &:global(.rs-checkbox-checked) {
    :global(.rs-checkbox-wrapper) {
      :global(.rs-checkbox-inner) {
        &::before {
          border-radius: 5px;
          border-color: var(--refined-teal) !important;
          background-color: var(--refined-teal) !important;
        }

        &::after {
          border-width: 0 1px 1px 0;
          width: 4px;
          height: 6px;
          margin-top: 4px;
          margin-left: 6px;
          opacity: 1;
          border-color: var(--light-teal) !important;
        }
      }
    }
  }
}
