@import 'styles/mixins';

.Container {
  display: flex;
  min-width: 0;

  div:not(:last-of-type) {
    margin-right: 6px;
  }

  .CardInfoContainer {
    display: flex;
    align-items: center;
    line-height: 24px;

    .UserName {
      margin-left: 12px;
    }

    > div {
      border-radius: 50%;
      height: 24px;
      width: 24px;
      font-size: 12px !important;
    }
  }
}

.Content {
  @include ellipsis();
}
